import { gql } from "@apollo/client";
import Label from "types/Label";
import LabelFragment from "../fragments/LabelFragment";

export interface CreateLabelInput {
  input: {
    title: string;
    colorHex: string;
  };
}

export interface CreateLabelPayload {
  createLabel: {
    errors: string[];
    label?: Label;
  };
}

const CREATE_LABEL = gql`
  mutation CreateLabel($input: CreateLabelMutationInput!) {
    createLabel(input: $input) {
      errors
      label {
        ...LabelFields
      }
    }
  }
  ${LabelFragment}
`;

export default CREATE_LABEL;