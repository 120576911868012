import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

interface CellProps {
    value: boolean;
}

const Cell = React.memo(function CustomerFunction(props: CellProps) {
    const { value } = props;
    const theme = useTheme();

    return <Typography variant="body2" fontWeight={600} color={!!value ? theme.palette.success.main : theme.palette.info.main}>{value ? "Published" : "Draft"}</Typography>;
});

export function render(params: GridCellParams) {
    return <Cell value={params.value as boolean} />;
}
