import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useQuery } from "@apollo/client";
import ARTICLES, { ArticlesInput, ArticlesPayload } from "graphql/queries/ArticlesQuery";
import Article from "types/Article";
import { GridColDef, GridSelectionModel, GridCellParams } from '@mui/x-data-grid-pro';
import DataGrid from "theme/DataGrid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { styled } from "@mui/material/styles";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

const HiddenColumnGrid = styled(DataGrid)<DataGridProProps>(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
        display: "none",
    },
    "&& > *": {
        borderRadius: "4px",
    }
}));

export function renderArticle(params: GridCellParams) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box sx={{ backgroundColor: params.value["colorHex"], width: "14px", height: "14px", borderRadius: "4px" }}></Box>
            <Typography variant="body2">{params.value["title"]}</Typography>
        </Stack>);
}

interface Props {
    open: boolean;
    selectedArticleId?: string;
    onClose: (selectedArticleId: string | undefined) => void;
}

const columns: GridColDef[] = [
    {
        field: 'article',
        headerName: '',
        renderCell: renderArticle,
        flex: 1,
        sortable: false
    }
];

export default function ChooseArticleDialog(props: Props) {

    const { onClose, open, selectedArticleId } = props;

    const [searchInput, setSearchInput] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(!!selectedArticleId ? [selectedArticleId] : ["unassigned"]);
    const theme = useTheme();

    const { data, error, loading } = useQuery<ArticlesPayload, ArticlesInput>(ARTICLES, {
        variables: {
            filters: {
                searchQuery: searchInput,
                live: true
            }
        }
    });

    useEffect(() => {
        if (!!error) {
            setAlertError("Unable to fetch");
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!selectedArticleId) {
            setSelectionModel([selectedArticleId]);
        } else {
            setSelectionModel(["unassigned"]);
        }
    }, [selectedArticleId]);

    const handleClose = (selection?: string[]) => {
        if (!!selection) {
            onClose(selection.filter((o) => o !== "unassigned").length > 0 ? selection.filter((o) => o !== "unassigned")[0] : undefined);
        } else {
            onClose(selectionModel.filter((o) => o !== "unassigned").length > 0 ? selectionModel.filter((o) => o !== "unassigned")[0] as string : undefined);
        }
        setTimeout(() => {
            setAlertError(undefined);
            setSearchInput("");
        }, 200);
    };

    const articles: Article[] = data?.articles?.nodes ?? [];

    let rows = articles.map((article) => {
        return {
            id: article.id,
            article: article
        }
    });

    rows = [{ id: "unassigned", article: { title: "Unassigned" } as any }, ...rows];

    if (searchInput.length > 0) {
        rows = rows.filter((obj) => obj.article.title.toLowerCase().includes(searchInput.toLowerCase()))
    }

    const renderTable = () => {
        return (
            <Stack direction="column" spacing={2}>
                <TextField
                    fullWidth
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    size="small"
                    placeholder="Filter articles"
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FilterListOutlinedIcon />
                            </InputAdornment>
                        ),
                    }} />
                {!loading && <div style={{ height: 300, width: '100%' }}>
                    <HiddenColumnGrid
                        density="compact"
                        rows={rows}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                            handleClose(newSelectionModel as string[]);
                        }}
                        columns={columns}
                        disableColumnMenu
                        disableColumnReorder
                        disableColumnResize
                        disableChildrenSorting
                        disableColumnSelector
                        hideFooter
                        headerHeight={0}
                        components={{
                            Panel: () => (<div />),
                            Header: () => (<div />),
                            Toolbar: () => (<div />)
                        }}
                    />
                </div>}
            </Stack>
        );
    }

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h5" fontWeight={600}>Select article</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    {renderTable()}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
            </DialogActions>
        </Dialog>
    );
}