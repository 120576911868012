import { gql } from "@apollo/client";
import User from "types/User";

export interface MeInput { }

export interface MePayload {
  me: User;
}

const ME = gql`
  query Me {
    me {
      id
      createdAt
      name
      email
      avatarUrl
      role
      avatarProcessing
      recentlyActive
      locked
      banned
      passwordNeedsSetting
      avatarRequired
      twoFactorRequired
      verifiedPhoneNumber
      verifiedEmail
      planRequired
      onboardingNag
      featureDropAlert
      memberships {
        pageInfo {
          totalCount
        }
        nodes {
          id
          name
        }
      }
      selectedOrg {
        id
        name
        hasEmailDomain
      }
    }
  }
`;

export default ME;
