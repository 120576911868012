import React from "react";
import AppBar from "theme/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { AdminButton } from "theme/Button";
import useTheme from "@mui/material/styles/useTheme";
import User from "types/User";

type Props = {
  children?: React.ReactNode;
  me: User;
};

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    prominent: true;
  }
}

export default function AdminAppBar(props: Props) {
  const { children } = props;
  const theme = useTheme();

  return (
    <Stack direction="column">
      <Box id="AppBar" sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0}>
          <Toolbar
            variant="dense"
            sx={{ bgcolor: theme.palette.prominent.main, paddingLeft: `${theme.spacing(2)} !important`, paddingRight: `${theme.spacing(2)} !important` }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <AdminButton onClick={() => { window.location.href = "https://admin.zenshop.app/#/settings"; }} color="inherit" variant="text">
                <Typography variant="h6" fontSize={25} color={theme.palette.common.white}>Return to Helpdesk settings</Typography>
              </AdminButton>
              <Box sx={{ flexGrow: 1 }}></Box>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </Stack >
  );
}
