import { useState, useEffect } from "react";
import useDebounce from "hooks/useDebounce";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import TextField from "theme/TextField";
import { GridRowsProp, GridColDef, GridSortDirection, GridSortModel, GridSelectionModel } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Button from "theme/Button";
import AddIcon from '@mui/icons-material/Add';
import ARTICLES, { ArticlesInput, ArticlesPayload, ArticlesSortInput } from "graphql/queries/ArticlesQuery";
import ARTICLES_COUNT, { ArticlesCountInput, ArticlesCountPayload } from "graphql/queries/ArticlesCountQuery";
import { useQuery } from "@apollo/client";
import useQueryParams from "hooks/useQueryParams";
import qs from 'query-string';
import Checkbox from "theme/Checkbox";
import { render as renderTitle } from "components/conversations/renderers/RenderTitle";
import { render as renderPublished } from "./renderers/RenderPublished";

export enum Selection {
    articles = "articles",
    createDraftArticle = "create_draft_article",
    editArticle = "edit_article",
    helpCenter = "helpcenter",
    settings = "settings",
    collections = "collections",
    messenger = "messenger",
}

export default function ArticlesGrid() {

    const history = useHistory();
    const query = useQueryParams();
    const selection = query.get("selection") as Selection || Selection.articles;

    const [page, setPage] = useState(Number(query.get("page")) ?? 0);
    const after = query.get("after") ?? undefined;
    const before = query.get("before") ?? undefined;

    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const debouncedSearchQuery = useDebounce<string | undefined>(searchQuery, 500);
    const [sort, setSort] = useState<ArticlesSortInput | undefined>({ title: "ASC" });
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'title',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const { data: countData, loading: countLoading, refetch: refetchCount } = useQuery<
        ArticlesCountPayload,
        ArticlesCountInput
    >(ARTICLES_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: {
                searchQuery: debouncedSearchQuery,
            },
        },
    });

    const { data, loading, refetch: refetch } = useQuery<
        ArticlesPayload,
        ArticlesInput
    >(ARTICLES, {
        fetchPolicy: "no-cache",
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                searchQuery: debouncedSearchQuery,
                sort: sort
            },
        },
    });

    const rows: GridRowsProp = (!!data && !!data.articles?.nodes)
        ? data.articles.nodes.map((row) => {
            return {
                id: row.id,
                title: row.title,
                live: row.live,
                author: row.author?.name
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "title",
            headerName: "Title",
            width: 300,
            renderCell: renderTitle
        },
        {
            field: "live",
            headerName: "State",
            width: 150,
            renderCell: renderPublished
        },
        {
            field: "author",
            headerName: "Author",
            width: 150,
        },
    ];

    const navigateToAfter = (after: string, page: number) => {
        const query = {
            page: page,
            after: after,
        };

        history.push({
            pathname: "/articles",
            search: qs.stringify(query)
        });
    };

    const navigateToBefore = (before: string, page: number) => {
        const query = {
            page: page,
            before: before,
        };

        history.push({
            pathname: "/articles",
            search: qs.stringify(query)
        });
    };

    const navigateStart = async () => {
        setPage(0);

        const query = {
            page: 0,
            selection: selection
        };

        try {
            await refetchCount();
            await refetch();

            history.push({
                pathname: "/articles",
                search: qs.stringify(query)
            });
        } catch { }
    }

    useEffect(() => {
        navigateStart();
    }, [sort, debouncedSearchQuery]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField value={searchQuery} size="small" placeholder="Filter results" onChange={(e) => {
                    setSearchQuery(e.target.value);
                }}></TextField>
                <div style={{ flexGrow: 1 }} />
                <Button size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => { history.push("/articles?selection=create_draft_article") }}>New article</Button>
            </Stack>
            <Box style={{ minHeight: 660 }}>
                <NoScrollZenDataGrid
                    style={{ height: "100%" }}
                    autoHeight={false}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "title": {
                                        setSort({ title: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "live": {
                                        setSort({ state: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "author": {
                                        setSort({ author: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        BaseCheckbox: Checkbox,
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    rows={rows}
                    columns={columns}
                    loading={loading || countLoading}
                    page={page}
                    onPageChange={(newPage) => {

                        if (loading || countLoading) {
                            return;
                        }

                        if (!!data) {
                            if (newPage > page) {
                                navigateToAfter(data.articles.pageInfo.endCursor, newPage);
                            } else {
                                navigateToBefore(data.articles.pageInfo.startCursor, newPage);
                            }
                        }

                        setPage(newPage);
                    }}
                    rowHeight={55}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowCount={!!countData ? countData.articlesCount : 0}
                    sortingMode="server"
                    paginationMode="server"
                    pagination
                    disableSelectionOnClick
                    onRowClick={(row) => history.push(`/articles?selection=edit_article&id=${row.id}`)}
                />
            </Box>
        </>
    );
}