import gql from "graphql-tag";

const ConversationTypeFragment = gql`
  fragment ConversationTypeFields on ConversationType {
    id
    createdAt
    title
    descriptionHtml
    system
  }
`;

export default ConversationTypeFragment;
