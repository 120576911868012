import React from "react";
import ThemeProvider from "theme/ThemeProvider";
import { AppContextProvider } from "AppContext";
import GraphQLProvider from "graphql/GraphQLProvider";
import Router from "routes/Router";
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SnackbarProvider } from 'notistack';
import './theme/remirror-editor.css';
import { StoreContext } from 'storeon/react';
import store from "store/Store";

function App() {
  return (
    <React.StrictMode>
      <StoreContext.Provider value={store}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <AppContextProvider>
            <ThemeProvider>
              <GraphQLProvider>
                <SnackbarProvider autoHideDuration={2500} hideIconVariant preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                  <Router />
                </SnackbarProvider>
              </GraphQLProvider>
            </ThemeProvider>
          </AppContextProvider>
        </LocalizationProvider>
      </StoreContext.Provider>
    </React.StrictMode>
  );
}

export default App;