import { styled, alpha } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from "@mui/material/ToggleButtonGroup";
import ZenLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';


export const SearchButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
  border: `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
  backgroundColor: "none",
  color: "darkgrey",
  textAlign: "start",
  paddingLeft: "12px",
  "&:hover.MuiButton-root": {
    backgroundColor: alpha("#708090", 0.1),
    border: `1px solid ${alpha(theme.palette.common.white, 0.6)}`,
  },
}));

export const LoadingButton = styled(ZenLoadingButton)<LoadingButtonProps>(({ theme, disabled, color }) => ({
  textTransform: "none",
  whiteSpace: "break-spaces",
  wordBreak: "break-word",
  textAlign: "left",
  "& .MuiButton-endIcon": {
    marginLeft: "0px",
  },
  "&.MuiButton-textPrimary": {
    color: theme.palette.text.primary,
    boxShadow: "none",
  },
  "&.MuiButton-textSecondary": {
    color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : theme.palette.text.secondary,
  },
  "&.MuiButton-containedPrimary": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedWarning": {
    color: "black",
    boxShadow: "none",
  },
  "&.MuiButton-containedSuccess": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedSecondary": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedError": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedInfo": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-colorInherit": {
    borderColor: theme.palette.border.main,
    color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : color === "error" ? theme.palette.error.main : color === "success" ? theme.palette.success.main : color === "info" ? theme.palette.info.main : theme.palette.text.secondary,
  },
  "&.MuiButton-outlinedInherit": {
    borderColor: "inherit",
  },
  color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : color === "error" ? theme.palette.error.main : color === "success" ? theme.palette.success.main : color === "info" ? theme.palette.info.main : theme.palette.text.secondary,
}));

const ZenButton = styled(Button)<ButtonProps>(({ theme, disabled, color }) => ({
  textTransform: "none",
  whiteSpace: "break-spaces",
  wordBreak: "break-word",
  textAlign: "left",
  "& .MuiButton-endIcon": {
    marginLeft: "0px",
  },
  "&.MuiButton-textPrimary": {
    color: theme.palette.text.primary,
    boxShadow: "none",
  },
  "&.MuiButton-textSecondary": {
    color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : theme.palette.text.secondary,
  },
  "&.MuiButton-containedPrimary": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedWarning": {
    color: "black",
    boxShadow: "none",
  },
  "&.MuiButton-containedSuccess": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedSecondary": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedError": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-containedInfo": {
    color: "white",
    boxShadow: "none",
  },
  "&.MuiButton-colorInherit": {
    borderColor: theme.palette.border.main,
    color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : color === "error" ? theme.palette.error.main : color === "success" ? theme.palette.success.main : color === "info" ? theme.palette.info.main : theme.palette.text.secondary,
  },
  "&.MuiButton-outlinedInherit": {
    borderColor: "inherit",
  },
  color: disabled === true ? alpha(theme.palette.text.secondary, 0.5) : color === "error" ? theme.palette.error.main : color === "success" ? theme.palette.success.main : color === "info" ? theme.palette.info.main : theme.palette.text.secondary,
}));

export const UpgradeButton = styled(ZenButton)<ButtonProps>(({ theme }) => ({
  fontWeight: 600,
  background: `linear-gradient(110deg, violet, ${theme.palette.primary.main})`,
  "&:hover": {
    background: `linear-gradient(110deg, violet, ${theme.palette.secondary.main})`,
  },
}));

export const MaxButton = styled(ZenButton)<ButtonProps>(({ theme }) => ({
  fontWeight: 600,
  background: `linear-gradient(110deg, rgb(245, 165, 36), rgb(243, 18, 96))`,
  "&:hover": {
    background: `linear-gradient(110deg, rgb(245, 165, 36), rgb(243, 18, 96))`,
  },
}));

export const LeftAlignedButton = styled(ZenButton)<ButtonProps>(({ theme, disabled }) => ({
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  wordBreak: "normal",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "#fbefff",
    color: theme.palette.purpleLove.main,
    border: "1px solid rgba(194,151,255,0.4)",
    "&:hover": {
      backgroundColor: `rgba(194,151,255,0.4)`,
    },
  },
  "&.MuiButton-textSecondary": {
    color: disabled === true ? alpha(theme.palette.purpleLove.main, 0.5) : theme.palette.purpleLove.main,
    border: "1px solid transparent"
  },
}));

export const AdminIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: "white",
  borderRadius: "4px",
  paddingLeft: "8px",
  paddingRight: "8px",
  "&.MuiIconButton-colorInherit": {
    color: "white",
  },
  "&.MuiIconButton-colorInherit:hover": {
    color: "#708090",
    backgroundColor: alpha("#708090", 0.2),
  },
}));

export const AdminButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  color: "white",
  "&.MuiButton-containedInherit": {
    paddingLeft: "8px",
    paddingRight: "8px",
    boxShadow: "none",
    color: "white",
    backgroundColor: alpha("#708090", 0.2),
  },
  "&.MuiButton-colorInherit:hover": {
    boxShadow: "none",
    color: "#708090",
    backgroundColor: alpha("#708090", 0.2),
  },
}));

export const FlatToggleButtonGroup = styled(ToggleButtonGroup)<ToggleButtonGroupProps>(
  ({ theme }) => ({

  })
);

export const FlatToggleButton = styled(ToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    textTransform: "none",
    "& .MuiToggleButton-sizeSmall": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .Mui-selected": {
      // backgroundColor: "rgba(0, 0, 0, 0.87)",
    },
  })
);

export default ZenButton;
