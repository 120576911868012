import { gql } from "@apollo/client";
import Article from "types/Article";
import ArticleFragment from "../fragments/ArticleFragment";

export interface CreateArticleInput {
  input: {
    title: string;
    subtitle?: string;
    contentHtml: string;
    live: boolean;
  };
}

export interface CreateArticlePayload {
  createArticle: {
    errors: string[];
    article?: Article;
  };
}

const CREATE_ARTICLE = gql`
  mutation CreateArticle($input: CreateArticleMutationInput!) {
    createArticle(input: $input) {
      errors
      article {
        ...ArticleFields
      }
    }
  }
  ${ArticleFragment}
`;

export default CREATE_ARTICLE;