import { useState, useEffect, useCallback } from "react";
import {
    LinkExtension,
    ShortcutHandlerProps,
} from 'remirror/extensions';
import {
    useActive,
    useChainedCommands,
    useCurrentSelection,
    useAttrs,
    useExtensionEvent,
} from '@remirror/react';
import DialogContent from "theme/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "theme/Button";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "theme/TextField";

interface AddLinkForm {
    link?: string;
}

function useLinkShortcut() {
    const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();

    useExtensionEvent(LinkExtension, 'onShortcut',
        useCallback(
            (props) => {
                return setLinkShortcut(props);
            },
            [],
        ),
    );

    return { linkShortcut };
}

const LinkEditor = (props: { open: boolean, onClose: () => void }) => {
    const { open, onClose } = props;
    const active = useActive();
    const url = (useAttrs().link()?.href as string) ?? '';
    const { to } = useCurrentSelection();
    const chain = useChainedCommands();
    const { linkShortcut } = useLinkShortcut();
    const [editing, setEditing] = useState<boolean>(active.link());

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors: formErrors },
    } = useForm();

    const handleClose = () => {
        onClose();
        reset();
        setTimeout(() => {
            reset();
        }, 200);
    }

    const submitHref = useCallback((inputUrl: string) => {
        const range = linkShortcut ?? undefined;

        if (inputUrl === '') {
            chain.removeLink();
        } else {
            chain.updateLink({ href: inputUrl, auto: false }, range);
        }

        chain
            .focus(range?.to ?? to)
            .run();

    }, [chain, to]);

    const onSubmit = (form: AddLinkForm) => {
        let link = form.link ?? "";
        if (!(link.includes("https://") || link.includes("http://"))) {
            link = `http://${link}`;
        }
        submitHref(link);
        handleClose();
    }

    useEffect(() => {
        if (!!editing) {
            setValue("link", url);
        }
    }, [url, editing]);

    useEffect(() => {
        if (!!open) {
            setEditing(active.link());
        }
    }, [open, active.link()]);

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {editing && <DialogTitle>Edit link</DialogTitle>}
                {!editing && <DialogTitle>Add link</DialogTitle>}
                <DialogContent dividers>
                    <FormControl variant="outlined">
                        <Controller
                            name="link"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id="link"
                                    type="link"
                                    size="small"
                                    helperText={formErrors.link ? formErrors.link.message : null}
                                    variant="outlined"
                                    placeholder="Link URL"
                                    error={!!formErrors.link}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue={url}
                            rules={{
                                pattern: {
                                    value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                    message: "Must be a valid link",
                                },
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={handleClose} size="small" variant='text' color="inherit">Close</Button>
                    <Button type='submit' size="small" variant='contained' color='success'>{editing ? 'Edit' : 'Add'}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default LinkEditor;