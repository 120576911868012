import { gql } from "@apollo/client";
import { ArticlesFilterInput } from "./ArticlesQuery";

export interface ArticlesCountInput {
    filters: ArticlesFilterInput;
}

export interface ArticlesCountPayload {
    articlesCount: number;
}

const ARTICLES_COUNT = gql`
  query ArticlesCount($filters: ArticleFilterInput!) {
    articlesCount(filters: $filters)
  }
`;

export default ARTICLES_COUNT;