import { useMemo, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "theme/Divider";
import { useHistory } from "react-router-dom";
import { LargeTextField, MediumTextField } from "theme/TextField";
import Avatar from "theme/Avatar";
import {
    BoldExtension,
    CalloutExtension,
    ItalicExtension,
    UnderlineExtension,
    MarkdownExtension,
    PlaceholderExtension,
    StrikeExtension,
    HeadingExtension,
    LinkExtension,
    BlockquoteExtension,
    BulletListExtension,
    OrderedListExtension
} from 'remirror/extensions';
import {
    EditorComponent,
    Remirror,
    useRemirror,
} from '@remirror/react';
import DialogContent from "theme/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button, { LoadingButton } from "theme/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ME, { MeInput, MePayload } from "graphql/queries/MeQuery";
import USER, { UserInput, UserPayload } from "graphql/queries/UserQuery";
import CREATE_ARTICLE, { CreateArticleInput, CreateArticlePayload } from "graphql/mutations/CreateArticleMutation";
import { useQuery, useMutation } from "@apollo/client";
import FormHelperText from '@mui/material/FormHelperText';
import Alert from "@mui/material/Alert";
import { useSnackbar } from 'notistack';
import Card from "theme/Card";
import { prosemirrorNodeToHtml } from 'remirror';
import MenuComponent from "components/zen_remirror/MenuComponent";
import LinkComponent from "components/zen_remirror/LinkComponent";

const ConfirmPublish = (props: { busy: boolean, open: boolean, onClose: (confirmed: boolean) => void }) => {
    const { open, onClose, busy } = props;

    const handleClose = (confirmed: boolean) => {
        onClose(confirmed);
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle>Save and publish</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">These changes will be published to the live Helpdesk, do you wish to proceed?</Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => handleClose(false)} size="small" variant='text' color="inherit">Cancel</Button>
                <LoadingButton loading={busy} onClick={() => handleClose(true)} size="small" variant='contained' color='success'>Publish</LoadingButton>
            </DialogActions>
        </Dialog >
    );
}

const InnerAvatar = (props: { id: string }) => {
    const { id } = props;

    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Avatar variant="rounded" src={data?.user?.avatarUrl}></Avatar>
    );
}

const UserAvatar = (props: { id: string | undefined }) => {
    const { id } = props;

    if (!!id) {
        return <InnerAvatar id={id} />
    } else {
        return <Avatar variant="rounded"></Avatar>;
    }
}

const AuthorButton = (props: { id: string }) => {
    const { id } = props;
    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Button sx={{ p: 0 }} endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            <Typography variant="caption" color="text.secondary" fontWeight={600}>{data?.user?.name || ""}</Typography>
        </Button>
    );
}

const AuthorText = (props: { id: string }) => {
    const { id } = props;
    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Typography variant="caption" color="text.secondary" fontWeight={600}>{data?.user?.name || ""}</Typography>
    );
}

const SaveArticleButtons = (props: { busy: boolean, validateForm: (published: boolean) => boolean, onSubmit: (published: boolean) => void }) => {

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const { validateForm, onSubmit, busy } = props;

    const history = useHistory();

    return (
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
            <ConfirmPublish open={confirmOpen} busy={busy} onClose={(confirm) => {
                setConfirmOpen(false);
                if (confirm) {
                    onSubmit(true);
                }
            }} />
            <Typography variant="h5">New Article</Typography>
            <div style={{ flexGrow: 1 }} />
            <div>
                <Button size="small" variant="text" color="inherit" onClick={() => history.push("/articles")}>Cancel</Button>
            </div>
            <div>
                <LoadingButton loading={busy} onClick={() => {
                    if (validateForm(false)) {
                        onSubmit(false);
                    }
                }} size="small" variant="contained" color="info">Save draft</LoadingButton>
            </div>
            <div>
                <LoadingButton loading={busy} onClick={() => {
                    if (validateForm(true)) {
                        setConfirmOpen(true);
                    }

                }} size="small" variant="contained" color="success">Publish</LoadingButton>
            </div>
        </Stack>
    );
}

export default function CreateDraftArticle() {

    const history = useHistory();

    const [busy, setBusy] = useState<boolean>(false);


    const [title, setTitle] = useState<string>("");
    const titleValid = Boolean(title.length > 0);

    const [subtitle, setSubtitle] = useState<string | undefined>(undefined);
    const subtitleValid = true;

    const [authorId, setAuthorId] = useState<string | undefined>(undefined);
    const [linkOpen, setLinkOpen] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [createArticle, { loading: createArticleLoading }] = useMutation<CreateArticlePayload, CreateArticleInput>(CREATE_ARTICLE, { refetchQueries: ["Articles", "ArticlesCount"] });

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const linkExtension = useMemo(() => {

        const extension = new LinkExtension({
            autoLink: true,
            defaultTarget: "_blank",
            defaultProtocol: "http:"
        });

        return extension;
    }, [linkOpen]);

    const { manager, state, onChange } = useRemirror({

        extensions: () => [
            linkExtension,
            new OrderedListExtension(),
            new BulletListExtension(),
            new BlockquoteExtension(),
            new HeadingExtension(),
            new PlaceholderExtension({ placeholder: "Write something..." }),
            new MarkdownExtension(),
            new UnderlineExtension(),
            new BoldExtension(),
            new ItalicExtension(),
            new CalloutExtension({ defaultType: 'warn' }),
            new StrikeExtension()
        ],

        selection: 'start',
        stringHandler: 'html',
    });

    const { data: meData } = useQuery<MePayload, MeInput>(ME, {
        variables: {},
    });

    const validateForm = (published: boolean): boolean => {
        setSubmitting(true);
        return (titleValid && subtitleValid);
    }

    const onSubmit = async (published: boolean) => {

        const contentHtml = prosemirrorNodeToHtml(state.doc);

        try {
            setBusy(true);

            const { data: response } = await createArticle({
                variables: {
                    input: {
                        title: title,
                        subtitle: subtitle,
                        contentHtml: contentHtml,
                        live: published
                    }
                }
            });

            if (!!(response?.createArticle?.article?.id)) {
                enqueueSnackbar(`🚀 Article ${published ? "published" : "saved as draft"}`);
                history.push("/articles");
            } else if (!!(response?.createArticle) && response.createArticle.errors.length > 0) {
                setAlertError(response.createArticle.errors[0]);
            }
        } catch {
            setAlertError("Something went wrong");
        } finally {
            setBusy(false);
        }
    }

    useEffect(() => {
        if (!authorId && !!meData?.me) {
            setAuthorId(meData.me.id);
        }
    }, [meData, authorId]);

    return (
        <Remirror manager={manager} initialContent={state} state={state} onChange={onChange}>
            <Stack direction="row" alignContent="center">
                <Stack direction="column" spacing={2} width="100%" maxWidth={900} alignItems="center">
                    <SaveArticleButtons busy={busy} validateForm={validateForm} onSubmit={onSubmit} />
                    <Card variant="outlined" sx={{ width: "100%", maxWidth: 900, p: 4 }}>
                        <Stack direction="column" spacing={2}>
                            {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                            <Stack direction="column" spacing={2}>
                                <Stack direction="column">
                                    <LargeTextField
                                        disabled={createArticleLoading}
                                        placeholder="Article title"
                                        onChange={(e) => setTitle(e.currentTarget.value)}
                                        value={title}
                                    />
                                    {!titleValid && submitting && <FormHelperText error>Please enter a title for the article</FormHelperText>}
                                    <Divider />
                                </Stack>
                                <Stack direction="column">
                                    <MediumTextField
                                        disabled={createArticleLoading}
                                        placeholder="Subtitle of the article"
                                        onChange={(e) => setSubtitle(e.currentTarget.value)}
                                        value={subtitle}
                                    />
                                    {!subtitleValid && submitting && <FormHelperText error>Please enter a subtitle for the article</FormHelperText>}
                                </Stack>
                                <Stack direction="row" spacing={2} alignContent="center">
                                    <UserAvatar id={authorId} />
                                    <Stack direction="column">
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            <Typography variant="caption" color="text.secondary">Written by</Typography>
                                            {!!authorId && <AuthorButton id={authorId} />}
                                        </Stack>
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography variant="caption" color="text.secondary">Last updated just now by</Typography>
                                            {!!authorId && <AuthorText id={authorId} />}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <div className='remirror-theme'>
                                <Stack direction="column" spacing={2}>
                                    <MenuComponent rounded setLinkOpen={setLinkOpen} />
                                    <EditorComponent />
                                    <LinkComponent open={linkOpen} onClose={() => setLinkOpen(false)} />
                                </Stack>
                            </div>
                        </Stack>
                    </Card>
                </Stack>
            </Stack >
        </Remirror>
    );
}