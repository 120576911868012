import { useState } from "react";
import Stack from '@mui/material/Stack';
import AppBar from "theme/AppBar";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from 'theme/Button';
import Card from 'theme/Card';
import HelpdeskMessenger from "types/HelpdeskMessenger";
import AddIcon from '@mui/icons-material/Add';
import useTheme from "@mui/material/styles/useTheme";
import { AdminButton } from "theme/Button";
import EditHelpdeskMessengerOptionPanel from "./topics/EditHelpdeskMessengerOptionPanel";
import AddHelpdeskMessengerOptionPanel from "./topics/AddHelpdeskMessengerOptionPanel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function HelpdeskOptionsPanel(props: { id: string, helpdesk: HelpdeskMessenger, onClose: () => void }) {
    const [addNewTopicOpen, setAddNewTopicOpen] = useState<boolean>(false);
    const [selectedHelpdeskOption, setSelectedHelpdeskOption] = useState<string | undefined>(undefined);

    const { onClose, id, helpdesk } = props;
    const theme = useTheme();
    const editHelpdeskOptionOpen = Boolean(selectedHelpdeskOption);
    const isFirstTopic = helpdesk.options.nodes.length <= 1;

    return (
        <Stack direction="column" style={{ width: "600px", backgroundColor: theme.palette.background.default }}>
            <AppBar color='prominent' variant='outlined' position='relative'>
                <Toolbar>
                    <Stack sx={{ width: "100%" }} alignItems="center" direction="row" spacing={1} justifyContent="space-between">
                        <Typography variant="h5" color={theme.palette.common.white}>Helpdesk topics</Typography>
                        <AdminButton onClick={onClose} color="inherit" size="small">Close</AdminButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Stack direction="column" p={3} spacing={2} style={{ overflowY: "auto", height: "100%" }}>
                {(!addNewTopicOpen && !editHelpdeskOptionOpen && isFirstTopic) && <Card variant="outlined">
                    <Stack direction="column" p={2} spacing={1}>
                        <Typography variant='h6'>Get started with Helpdesk Topics</Typography>
                        <Stack direction="column" spacing={2}>
                            <div>
                                <Button onClick={() => {
                                    window.open("https://support.zenshop.app/using-messenger/prompt-customers-for-information", "_blank");
                                }}
                                    size="small" color="inherit" variant="text" startIcon={<OpenInNewIcon />}>See our guide on getting started with helpdesk topics</Button>
                            </div>
                            <div>
                                <Button size="small" startIcon={<AddIcon />} variant='contained' color='secondary' onClick={() => {
                                    setAddNewTopicOpen(true);
                                }}>Create your first topic</Button>
                            </div>
                        </Stack>
                    </Stack>
                </Card>}
                {!isFirstTopic && <Stack spacing={1}>
                    <Typography variant="caption" color="text.secondary">Priortize the most important topics to start the conversation</Typography>
                </Stack>}
                {!isFirstTopic && <Card variant="outlined">
                    <Stack direction="column" p={2} pt={1} sx={{ flexFlow: "row wrap", justifyContent: "end" }}>
                        {helpdesk.options.nodes.map((option) => {
                            return (<Button
                                onClick={() => {
                                    setAddNewTopicOpen(false);
                                    setSelectedHelpdeskOption(option.id);
                                }}
                                key={option.id}
                                color="info"
                                variant='outlined'
                                size="small"
                                sx={{ ml: 1, mt: 1 }}>
                                {option.questionText}
                            </Button>);
                        })}
                    </Stack>
                    <Stack direction="column" p={2} pt={0} sx={{ flexFlow: "row wrap", justifyContent: "end" }}>
                        <Button startIcon={<AddIcon />} disabled={addNewTopicOpen} variant='contained' size="small" color="secondary" onClick={() => {
                            setSelectedHelpdeskOption(undefined);
                            setAddNewTopicOpen(true);
                        }}>New topic</Button>
                    </Stack>
                </Card>}
                {(!addNewTopicOpen && !editHelpdeskOptionOpen) && !isFirstTopic && <Typography align="center" variant="caption" color="text.secondary">Select a topic to begin editing</Typography>}
                {!!addNewTopicOpen && <AddHelpdeskMessengerOptionPanel id={id} onCancel={() => { setAddNewTopicOpen(false); }} />}
                {!!selectedHelpdeskOption && <EditHelpdeskMessengerOptionPanel draftHelpdeskMessengerId={id} id={selectedHelpdeskOption} onCancel={() => setSelectedHelpdeskOption(undefined)} />}
            </Stack>
        </Stack>
    );
}

export default HelpdeskOptionsPanel;
