import { styled } from "@mui/material/styles";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import Badge from '@mui/material/Badge';

export const AvatarBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    },
}));

const ZenAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
    "&.MuiAvatar-rounded": {
        backgroundColor: theme.palette.border.main,
        borderRadius: theme.shape.borderRadius,
    },
}));

export default ZenAvatar;
