import { gql } from "@apollo/client";
import User from "types/User";

export interface UserInput {
  id: string;
}

export interface UserPayload {
  user?: User;
}

// QUERY IS ONLY FOR PUBLIC DATA

const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      createdAt
      name
      avatarUrl
    }
  }
`;

export default USER;