import { gql } from "@apollo/client";

export interface DeleteArticleInput {
    input: {
        id: string;
    };
}

export interface DeleteArticlePayload {
    deleteArticle?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_ARTICLE = gql`
  mutation DeleteArticle($input: DeleteArticleMutationInput!) {
    deleteArticle(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_ARTICLE;
