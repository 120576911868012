import { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "theme/Divider";
import { useHistory } from "react-router-dom";
import { LargeTextField, MediumTextField } from "theme/TextField";
import useQueryParams from "hooks/useQueryParams";
import Avatar from "theme/Avatar";
import {
    BoldExtension,
    CalloutExtension,
    ItalicExtension,
    UnderlineExtension,
    MarkdownExtension,
    PlaceholderExtension,
    StrikeExtension,
    HeadingExtension,
    LinkExtension,
    BlockquoteExtension,
    BulletListExtension,
    OrderedListExtension
} from 'remirror/extensions';
import {
    EditorComponent,
    Remirror,
    useRemirror,
} from '@remirror/react';
import DialogContent from "theme/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button, { LoadingButton } from "theme/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ARTICLE, { ArticleInput, ArticlePayload } from "graphql/queries/ArticleQuery";
import USER, { UserInput, UserPayload } from "graphql/queries/UserQuery";
import UPDATE_ARTICLE, { UpdateArticleInput, UpdateArticlePayload } from "graphql/mutations/UpdateArticleMutation";
import { useQuery, useMutation } from "@apollo/client";
import FormHelperText from '@mui/material/FormHelperText';
import Alert from "@mui/material/Alert";
import { useSnackbar } from 'notistack';
import Article from "types/Article";
import Drawer from 'theme/Drawer';
import AppBar from "theme/AppBar";
import Toolbar from '@mui/material/Toolbar';
import { AdminButton } from "theme/Button";
import useTheme from "@mui/material/styles/useTheme";
import Card from "theme/Card";
import Checkbox from "theme/Checkbox";
import DELETE_ARTICLE, { DeleteArticleInput, DeleteArticlePayload } from "graphql/mutations/DeleteArticleMutation";
import { prosemirrorNodeToHtml } from 'remirror';
import MenuComponent from "components/zen_remirror/MenuComponent";
import LinkComponent from "components/zen_remirror/LinkComponent";
import { formatDateStringToTimeAgo } from "helpers/DateFormatters";

const ConfirmPublish = (props: { busy: boolean, open: boolean, onClose: (confirmed: boolean) => void }) => {
    const { open, onClose, busy } = props;

    const handleClose = (confirmed: boolean) => {
        onClose(confirmed);
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle>Save and publish</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">These changes will be published to the live Helpdesk, do you wish to proceed?</Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => handleClose(false)} size="small" variant='text' color="inherit">Cancel</Button>
                <LoadingButton loading={busy} onClick={() => handleClose(true)} size="small" variant='contained' color='success'>Publish</LoadingButton>
            </DialogActions>
        </Dialog >
    );
}

const ConfirmMoveToDrafts = (props: { busy: boolean, open: boolean, onClose: (confirmed: boolean) => void }) => {
    const { open, onClose, busy } = props;

    const handleClose = (confirmed: boolean) => {
        onClose(confirmed);
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle>Move to drafts</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2">This article will be moved to your drafts and no longer be shown on the Helpdesk, do you wish to proceed?</Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => handleClose(false)} size="small" variant='text' color="inherit">Cancel</Button>
                <LoadingButton loading={busy} onClick={() => handleClose(true)} size="small" variant='contained' color='success'>Move to drafts</LoadingButton>
            </DialogActions>
        </Dialog >
    );
}

const InnerAvatar = (props: { id: string }) => {
    const { id } = props;

    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Avatar variant="rounded" src={data?.user?.avatarUrl}></Avatar>
    );
}

const UserAvatar = (props: { id: string | undefined }) => {
    const { id } = props;

    if (!!id) {
        return <InnerAvatar id={id} />
    } else {
        return <Avatar variant="rounded"></Avatar>;
    }
}

const AuthorButton = (props: { id: string }) => {
    const { id } = props;
    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Button sx={{ p: 0 }} endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            <Typography variant="caption" color="text.secondary" fontWeight={600}>{data?.user?.name || ""}</Typography>
        </Button>
    );
}

const AuthorText = (props: { id: string }) => {
    const { id } = props;
    const { data } = useQuery<UserPayload, UserInput>(USER, {
        variables: { id: id },
    });

    return (
        <Typography variant="caption" color="text.secondary" fontWeight={600}>{data?.user?.name || ""}</Typography>
    );
}

const SaveArticleButtons = (props: { busy: boolean, article: Article, validateForm: (published: boolean) => boolean, onSubmit: (published: boolean) => void }) => {

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [moveToDraftsOpen, setMoveToDraftsOpen] = useState<boolean>(false);
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

    const { article, validateForm, onSubmit, busy } = props;

    const theme = useTheme();
    const history = useHistory();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const [updateArticle, { loading: updateArticleLoading }] = useMutation<UpdateArticlePayload, UpdateArticleInput>(UPDATE_ARTICLE, { refetchQueries: ["Articles", "ArticlesCount"] });
    const [deleteArticle, { loading: deleteArticleLoading }] = useMutation<DeleteArticlePayload, DeleteArticleInput>(DELETE_ARTICLE, { refetchQueries: ["Articles", "ArticlesCount"] });
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Stack width="100%" direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
            <ConfirmPublish busy={busy} open={confirmOpen} onClose={(confirm) => {
                setConfirmOpen(false);
                if (confirm) {
                    onSubmit(true);
                }
            }} />
            <ConfirmMoveToDrafts busy={busy} open={moveToDraftsOpen} onClose={(confirm) => {
                setMoveToDraftsOpen(false);

                if (confirm) {
                    onSubmit(false);
                }
            }} />
            <Typography variant="h5">Edit Article</Typography>
            <div style={{ flexGrow: 1 }} />
            <div>
                <Button size="small" variant="text" color="inherit" onClick={() => history.push("/articles")}>Cancel</Button>
            </div>
            <div>
                <Drawer anchor="right" open={optionsOpen} onClose={() => { setOptionsOpen(false); }}>
                    <Stack direction="column" style={{ width: "600px", backgroundColor: theme.palette.background.default }}>
                        <AppBar color='prominent' variant='outlined' position='relative'>
                            <Toolbar>
                                <Stack sx={{ width: "100%" }} alignItems="center" direction="row" spacing={1} justifyContent="space-between">
                                    <Typography variant="h5" color={theme.palette.common.white}>Article options</Typography>
                                    <AdminButton onClick={() => { setOptionsOpen(false); }} color="inherit" size="small">Close</AdminButton>
                                </Stack>
                            </Toolbar>
                        </AppBar>
                    </Stack>
                    <Stack direction="column" p={3} spacing={2} style={{ overflowY: "auto", height: "100%" }}>
                        {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                        <Card variant='outlined' sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant='h6'>Messenger settings</Typography>
                                    <Divider />
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Checkbox disabled={deleteArticleLoading || updateArticleLoading}></Checkbox>
                                    <Typography variant="body2">Exclude article from messenger</Typography>
                                </Stack>
                            </Stack>
                        </Card>
                        <Card variant='outlined' sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant='h6'>Danger zone</Typography>
                                    <Divider />
                                </Stack>
                                <Typography variant="body2">Deleting the article cannot be undone</Typography>
                                <div>
                                    <Button disabled={deleteArticleLoading || updateArticleLoading} variant="contained" color="error" size="small" onClick={async () => {
                                        try {
                                            const { data } = await deleteArticle({ variables: { input: { id: article.id } } });

                                            if (!!(data?.deleteArticle?.ok)) {
                                                enqueueSnackbar("☠️ Article deleted");
                                                history.push("/articles");
                                            } else if (!!data && !!data.deleteArticle && data.deleteArticle.errors.length > 0) {
                                                setAlertError(data.deleteArticle.errors[0]);
                                            }
                                        } catch {
                                            setAlertError("Something went wrong");
                                        }
                                    }}>Delete article</Button>
                                </div>
                            </Stack>
                        </Card>
                    </Stack>
                </Drawer>
                <Button size="small" endIcon={<ArrowDropDownIcon />} variant="text" color="inherit" onClick={() => setOptionsOpen(true)}>Options</Button>
            </div>
            {
                !article.live && <div>
                    <LoadingButton
                        loading={busy}
                        onClick={() => {
                            if (validateForm(false)) {
                                onSubmit(false);
                            }
                        }} size="small" variant="contained" color="info">Save</LoadingButton>
                </div>
            }
            {
                article.live && <div>
                    <LoadingButton
                        loading={busy}
                        onClick={() => {
                            if (validateForm(false)) {
                                setMoveToDraftsOpen(true);
                            }
                        }} size="small" variant="contained" color="info">Move to drafts</LoadingButton>
                </div>
            }
            {
                !article.live && <div>
                    <LoadingButton
                        loading={busy}
                        onClick={() => {
                            if (validateForm(true)) {
                                setConfirmOpen(true);
                            }

                        }} size="small" variant="contained" color="success">Publish</LoadingButton>
                </div>
            }
            {
                article.live && <div>
                    <LoadingButton
                        loading={busy}
                        onClick={() => {
                            if (validateForm(true)) {
                                onSubmit(true);
                            }
                        }} size="small" variant="contained" color="success">Save</LoadingButton>
                </div>
            }
        </Stack>
    );
}

export default function EditArticleWrapper() {

    const query = useQueryParams();
    const id = query.get("id") as string;

    const { data } = useQuery<ArticlePayload, ArticleInput>(ARTICLE, {
        variables: { id: id },
    });

    if (!!data?.article) {
        return <EditArticle article={data.article} />
    }

    return <div />
}

function EditArticle(props: { article: Article }) {

    const { article } = props;

    const history = useHistory();

    const [busy, setBusy] = useState<boolean>(false);

    const [title, setTitle] = useState<string>(article.title);
    const titleValid = Boolean(title.length > 0);

    const [subtitle, setSubtitle] = useState<string | undefined>(article.subtitle);
    const subtitleValid = true;

    const [contentHtml, setContentHtml] = useState<string>(article.contentHtml);
    const contentHtmlValid = Boolean(contentHtml.length > 0);

    const [authorId, setAuthorId] = useState<string | undefined>(article.author?.id);
    const [linkOpen, setLinkOpen] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [updateArticle, { loading: updateArticleLoading }] = useMutation<UpdateArticlePayload, UpdateArticleInput>(UPDATE_ARTICLE, { refetchQueries: ["Articles", "ArticlesCount"] });

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const linkExtension = useMemo(() => {

        const extension = new LinkExtension({
            autoLink: true,
            defaultTarget: "_blank",
            defaultProtocol: "http:"
        });

        return extension;
    }, [linkOpen]);

    const { manager, state, onChange } = useRemirror({

        extensions: () => [
            linkExtension,
            new OrderedListExtension(),
            new BulletListExtension(),
            new BlockquoteExtension(),
            new HeadingExtension(),
            new PlaceholderExtension({ placeholder: "Write something..." }),
            new MarkdownExtension(),
            new UnderlineExtension(),
            new BoldExtension(),
            new ItalicExtension(),
            new CalloutExtension({ defaultType: 'warn' }),
            new StrikeExtension()
        ],

        selection: 'start',
        stringHandler: 'html',
        content: contentHtml,
    });

    const validateForm = (published: boolean): boolean => {
        const contentHtml = prosemirrorNodeToHtml(state.doc);
        setContentHtml(contentHtml);
        setSubmitting(true);
        return (titleValid && subtitleValid && contentHtml.length > 0);
    }

    const onSubmit = async (published: boolean) => {

        const contentHtml = prosemirrorNodeToHtml(state.doc);

        try {
            setBusy(true);

            const { data: response } = await updateArticle({
                variables: {
                    input: {
                        id: article.id,
                        title: title,
                        subtitle: subtitle,
                        contentHtml: contentHtml,
                        authorId: article.author.id,
                        live: published
                    }
                }
            });

            if (!!(response?.updateArticle?.article?.id)) {
                enqueueSnackbar(`🚀 Article ${published ? "published" : "saved as draft"}`);
                history.push("/articles");
            } else if (!!(response?.updateArticle) && response.updateArticle.errors.length > 0) {
                setAlertError(response.updateArticle.errors[0]);
            }
        } catch {
            setAlertError("Something went wrong");
        } finally {
            setBusy(false);
        }
    }

    return (
        <Remirror manager={manager} initialContent={state} state={state} onChange={onChange}>
            <Stack direction="row" alignContent="center">
                <Stack direction="column" spacing={2} width="100%" maxWidth={900}>
                    <SaveArticleButtons busy={busy} article={article} validateForm={validateForm} onSubmit={onSubmit} />
                    <Card variant="outlined" sx={{ width: "100%", p: 4 }}>
                        <Stack direction="column" spacing={2}>
                            {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                            <Stack direction="column" spacing={2}>
                                <Stack direction="column">
                                    <LargeTextField
                                        disabled={updateArticleLoading}
                                        placeholder="Article title"
                                        onChange={(e) => setTitle(e.currentTarget.value)}
                                        value={title}
                                    />
                                    {!titleValid && submitting && <FormHelperText error>Please enter a title for the article</FormHelperText>}
                                    <Divider />
                                </Stack>
                                <Stack direction="column">
                                    <MediumTextField
                                        disabled={updateArticleLoading}
                                        placeholder="Subtitle of the article"
                                        onChange={(e) => setSubtitle(e.currentTarget.value)}
                                        value={subtitle}
                                    />
                                    {!subtitleValid && submitting && <FormHelperText error>Please enter a subtitle for the article</FormHelperText>}
                                </Stack>
                                <Stack direction="row" spacing={2} alignContent="center">
                                    <UserAvatar id={authorId} />
                                    <Stack direction="column">
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            <Typography variant="caption" color="text.secondary">Written by</Typography>
                                            {!!authorId && <AuthorButton id={authorId} />}
                                        </Stack>
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography variant="caption" color="text.secondary">Last updated {formatDateStringToTimeAgo(article.updatedAt, true)} by</Typography>
                                            {!!authorId && <AuthorText id={authorId} />}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <div className='remirror-theme'>
                                {!contentHtmlValid && submitting && <FormHelperText sx={{ pb: 1 }} error>Please enter some content for the article</FormHelperText>}
                                <MenuComponent rounded setLinkOpen={setLinkOpen} />
                                <EditorComponent />
                                <LinkComponent open={linkOpen} onClose={() => setLinkOpen(false)} />
                            </div>
                        </Stack>
                    </Card>
                </Stack>
            </Stack >
        </Remirror>
    );
}