import gql from "graphql-tag";

const HelpdeskMessengerFragment = gql`
  fragment HelpdeskMessengerFields on HelpdeskMessenger {
    id
    name
    live
    messengerLocation
    faqEnabled
    faqSuggestionsEnabled
    somethingElseEnabled
    somethingElseText
    greetingText
    greetingDescriptionHtml
    needMoreHelpText
    contactSupportTitle
    contactSupportPlaceholder
    contactSupportEmail
    contactSupportSend
    thankyouTitle
    thankyouMessageHtml
    haveAnotherQuestionText
    specialNoticeShown
    specialNoticeText
    darkmodeSupported
    backgroundColor
    actionColor
    backgroundPattern
    backgroundPatternSelection
    promptForHelpdeskOptions
    shopifyDeepLink
  }
`;

export default HelpdeskMessengerFragment;