import { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import TextField from "theme/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const ColorPickerModal = (props: { id: string, defaultValue: string, value?: string, onChange: (color: string) => void }) => {

    const { id, onChange, defaultValue, value } = props;
    const theme = useTheme();
    const [color, setColor] = useState(defaultValue);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const isValid = /^#([0-9A-F]{3}){1,2}$/i.test(color);

    useEffect(() => {
        if (isValid && defaultValue !== color) {
            onChange(color);
        }
    }, [color]);


    useEffect(() => {
        if (!!value && color !== value) {
            setColor(value);
        }
    }, [value]);

    return (
        <>
            <div>
                <TextField sx={{ maxWidth: 150 }} size="small" value={color} error={!isValid}
                    helperText={!isValid ? "This is not a valid color code" : null}
                    onChange={(e) => {
                        setColor(e.currentTarget.value);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                size="small"
                                aria-describedby={id}
                                onClick={handleClick}
                            >
                                <Box sx={{ border: '1px solid rgba(0,0,0,0.2)', backgroundColor: color, width: 20, height: 20, borderRadius: `${theme.shape.borderRadius}px` }} />
                            </IconButton>
                        </InputAdornment>
                    }} />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <HexColorPicker color={color} onChange={setColor} />
                </Box>
            </Popover>
        </>
    );
};

export default ColorPickerModal;