import React from "react";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useApp } from "AppContext";
import PusherLink from "graphql-ruby-client/subscriptions/PusherLink";
import Pusher from "pusher-js";
import pako from "pako";
import LogRocket from 'logrocket';

type Props = {
  children?: React.ReactNode;
};

function GraphQLProvider(props: Props) {
  const { token } = useApp();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY!, {
    authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
    auth: { headers: { "Authorization": token ? `Bearer ${token}` : "" } },
    cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
  });

  const pusherLink = new PusherLink({
    pusher: pusherClient,
    decompress: function (compressed) {
      // Decode base64
      const data = atob(compressed)
        .split('')
        .map(x => x.charCodeAt(0));
      // Decompress
      const payloadString = pako.inflate(new Uint8Array(data), { to: 'string' });
      // Parse into an object
      return JSON.parse(payloadString);
    }
  });

  var recordingURL = "";

  if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
    // This is a production build
    LogRocket.getSessionURL(function (sessionURL) {
      recordingURL = sessionURL;
    });
  }

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-LogRocket-URL': recordingURL,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const link = ApolloLink.from([authLink, pusherLink, httpLink]);

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default GraphQLProvider;
