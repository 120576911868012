import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

interface MessageProps {
    value: string;
}

const Cell = React.memo(function CustomerFunction(props: MessageProps) {
    const { value } = props;

    return <Typography variant="body2" fontWeight={600}>{value}</Typography>;
});

export function render(params: GridCellParams) {
    return <Cell value={params.value as string} />;
}
