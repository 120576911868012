import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Chip from "theme/Chip";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";

interface Label {
  id: string;
  title: string;
  colorHex: string;
}

interface LabelProps {
  value: Label[];
}

const LabelCell = React.memo(function LabelFunction(props: LabelProps) {
  const { value } = props;
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        justifyContent: "flex-start",
        alignContent: "space-evenly",
        height: "100%",
      }}
    >
      {value.map((label) => (
        <Chip
          size="small"
          key={label.id}
          label={<Typography fontWeight={600} variant="caption">{label.title}</Typography>}
          variant="filled"
          sx={{
            marginRight: theme.spacing(1),
            bgcolor: label.colorHex,
            color: theme.palette.getContrastText(label.colorHex),
          }}
        />
      ))}
    </Stack>
  );
});

export function renderLabel(params: GridCellParams) {
  return <LabelCell value={params.value as Label[]} />;
}
