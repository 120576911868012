import { gql } from "@apollo/client";
import HelpdeskMessengerFragment from "graphql/fragments/HelpdeskMessengerFragment";
import HelpdeskMessenger from "types/HelpdeskMessenger";

export interface ToggleHelpdeskMessengerInput {
    input: {
        helpdeskMessengerId: string;
    };
}

export interface ToggleHelpdeskMessengerPayload {
    toggleHelpdeskMessenger: {
        helpdeskMessenger?: HelpdeskMessenger;
    };
}

const TOGGLE_HELPDESK_MESSENGER = gql`
  mutation ToggleHelpdeskMessenger($input: ToggleHelpdeskMessengerMutationInput!) {
    toggleHelpdeskMessenger(input: $input) {
        helpdeskMessenger {
          ...HelpdeskMessengerFields
        }
    }
  } 
  ${HelpdeskMessengerFragment}
`;

export default TOGGLE_HELPDESK_MESSENGER;
