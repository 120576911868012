import { gql } from "@apollo/client";

export interface DeleteHelpdeskMessengerOptionInput {
  input: {
    id: string;
  };
}

export interface DeleteHelpdeskMessengerOptionPayload {
  deleteHelpdeskMessengerOption: {
    errors: string[];
    ok?: boolean;
  };
}

const DELETE_HELPDESK_MESSENGER_OPTION = gql`
  mutation DeleteHelpdeskMessengerOption($input: DeleteHelpdeskMessengerOptionMutationInput!) {
    deleteHelpdeskMessengerOption(input: $input) {
      errors
      ok 
    }
  }
`;

export default DELETE_HELPDESK_MESSENGER_OPTION;