import { styled } from "@mui/material/styles";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";

const ZenDialogTitle = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
    "&.MuiDialogTitle-root": {
        fontStyle: "normal",
        lineHeight: "normal",
        fontWeight: 700,
        fontSize: 25,
        color: "rgb(36 24 91)",
        fontFamily: "P22MackinacPro-Bold, Times New Roman, Times, serif",
    },
}));

export default ZenDialogTitle;
