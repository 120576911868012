import { styled } from "@mui/material/styles";
import Divider, { DividerProps } from "@mui/material/Divider";

const ZenDivider = styled(Divider)<DividerProps>(({ theme }) => ({
    "&.MuiDivider-root": {
        borderColor: theme.palette.border.main,
    },
}));

export default ZenDivider;
