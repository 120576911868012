import gql from "graphql-tag";

const ArticleFragment = gql`
  fragment ArticleFields on Article {
    id
    createdAt
    updatedAt
    title
    subtitle
    live
  }
`;

export default ArticleFragment;
