import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "../fragments/HelpdeskMessengerFragment";

export interface CreateDraftHelpdeskMessengerInput {
  input: {
    helpdeskMessengerId: string;
  };
}

export interface CreateDraftHelpdeskMessengerPayload {
  createDraftHelpdeskMessenger: {
    errors: string[];
    draftHelpdeskMessenger?: HelpdeskMessenger;
  };
}

const CREATE_DRAFT_HELPDESK_MESSENGER = gql`
  mutation CreateDraftHelpdeskMessenger($input: CreateDraftHelpdeskMessengerMutationInput!) {
    createDraftHelpdeskMessenger(input: $input) {
      errors
      draftHelpdeskMessenger {
        ...HelpdeskMessengerFields
      }
    }
  }
  ${HelpdeskMessengerFragment}
`;

export default CREATE_DRAFT_HELPDESK_MESSENGER;