import { gql } from "@apollo/client";
import Article from "types/Article";
import ArticleFragment from "../fragments/ArticleFragment";

export interface UpdateArticleInput {
  input: {
    id: string;
    title: string;
    subtitle?: string;
    contentHtml: string;
    live: boolean;
    authorId: string;
  };
}

export interface UpdateArticlePayload {
  updateArticle: {
    errors: string[];
    article?: Article;
  }
}

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $input: UpdateArticleMutationInput!
  ) {
    updateArticle(input: $input) {
      errors
      article {
        ...ArticleFields
        contentHtml
      }
    }
  }
  ${ArticleFragment}
`;

export default UPDATE_ARTICLE;