import React, { useMemo } from "react";
import type { } from "@mui/lab/themeAugmentation";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StyledEngineProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI!);

type Props = {
  children?: React.ReactNode;
};

declare module "@mui/material/styles" {

  interface Palette {
    border: Palette["primary"];
    prominent: Palette["primary"];
    attention: Palette["primary"];
    attentionBorder: Palette["primary"];
    selected: Palette["primary"];
    purpleLove: Palette["primary"];
    notification: Palette["primary"];
    subtle: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    border?: PaletteOptions["primary"];
    prominent?: PaletteOptions["primary"];
    attention?: PaletteOptions["primary"];
    attentionBorder?: PaletteOptions["primary"];
    selected?: PaletteOptions["primary"];
    purpleLove?: PaletteOptions["primary"];
    notification?: PaletteOptions["primary"];
    subtle?: PaletteOptions["primary"];
  }
}

function ThemedApp(props: Props) {
  const mediaQueryPrefersDarkMode = useMediaQuery(
    "(prefers-color-scheme: dark)"
  );

  // const prefersDarkMode = app.prefersDarkMode ?? mediaQueryPrefersDarkMode;

  const prefersDarkMode = false;

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          body1: {
            fontSize: '18px',
            fontFamily: 'Mona-Sans',
            fontWeight: 300,
            lineHeight: '31px',
          },
          body2: {
            fontSize: '18px',
            fontFamily: 'Mona-Sans',
            fontWeight: 300,
            lineHeight: '31px',
          },
          caption: {
            fontSize: '14px',
            fontFamily: 'Mona-Sans',
            fontWeight: 300,
            lineHeight: '25px',
          },
          subtitle1: {
            fontSize: '18px',
            fontFamily: 'Mona-Sans',
            fontWeight: 500,
            lineHeight: '31px',
          },
          subtitle2: {
            fontSize: '18px',
            fontFamily: 'Mona-Sans',
            fontWeight: 500,
            lineHeight: '31px',
          },
          h1: {
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700,
            fontSize: 50,
            color: "rgb(36 24 91)",
            fontFamily: "P22MackinacExtraBold, Times New Roman, Times, serif"
          },
          h2: {
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700,
            fontSize: 45,
            color: "rgb(36 24 91)",
            fontFamily: "P22MackinacExtraBold, Times New Roman, Times, serif"
          },
          h3: {
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700,
            fontSize: 40,
            color: "rgb(36 24 91)",
            fontFamily: "P22MackinacExtraBold, Times New Roman, Times, serif"
          },
          h4: {
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700,
            fontSize: 35,
            color: "rgb(36 24 91)",
            fontFamily: "P22MackinacExtraBold, Times New Roman, Times, serif"
          },
          h5: {
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 700,
            fontSize: 25,
            color: "rgb(36 24 91)",
            fontFamily: "P22MackinacPro-Bold, Times New Roman, Times, serif"
          },
          h6: {
            fontStyle: "normal",
            lineHeight: "59px",
            fontWeight: 500,
            fontSize: 37,
            color: "rgb(0 0 0)",
            fontFamily: "Mona-Sans, serif"
          },

          fontFamily: "Mona-Sans, Inter, sans-serif",
        },
        shape: {
          borderRadius: 4,
        },
        components: {
          MuiTextField: {
            defaultProps: {
              InputLabelProps: {
                shrink: true
              }
            }
          },
          MuiToggleButton: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          subtle: {
            main: "rgb(246, 248, 250)"
          },
          notification: {
            main: "rgb(225, 243, 254)"
          },
          attention: {
            main: "rgb(255, 248, 197)",
          },
          attentionBorder: {
            main: "rgba(212, 167, 44, 0.4)",
          },
          border: {
            main: "rgb(208, 215, 222)",
          },
          text: {
            secondary: "rgb(87, 96, 106)",
          },
          success: {
            main: "rgb(45, 164, 78)",
          },
          warning: {
            main: "rgb(255, 211, 61)",
          },
          error: {
            main: "rgb(207, 34, 46)",
          },
          info: {
            main: `rgb(191, 57, 137)`,
          },
          prominent: {
            main: `rgb(22, 27, 34)`,
            contrastText: "#fff",
          },
          primary: {
            main: `rgb(73, 75, 255)`,
          },
          secondary: {
            main: `rgb(130, 80, 223)`,
          },
          purpleLove: {
            main: "rgb(36 24 91)",
          },
          divider: "rgba(208, 215, 222, 1.0)",
          background: {
            default: prefersDarkMode ? `rgb(13, 17, 23)` : `rgb(250, 250, 250)`,
            paper: prefersDarkMode ? "red" : "rgb(255, 255, 255)",
          },
          selected: {
            main: "rgba(194,151,255,0.4)",
          }
        },
      }),
    [prefersDarkMode]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            p: {
              marginBlockStart: "0em",
              marginBlockEnd: theme.spacing(1),
            },
          }}
        />
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemedApp;
