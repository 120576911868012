import { styled } from "@mui/material/styles";
import Card, { CardProps } from "@mui/material/Card";

export const BackgroundCard = styled(Card)<CardProps>(({ theme }) => ({
    "&.MuiCard-root": {
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
    },
}));

export const InnerCard = styled(Card)<CardProps>(({ theme }) => ({
    "&.MuiCard-root": {
        backgroundColor: theme.palette.mode === "light" ? "rgb(246, 248, 250)" : "red",
        borderRadius: theme.shape.borderRadius,
    },
}));

const ZenCard = styled(Card)<CardProps>(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
}));

export default ZenCard;
