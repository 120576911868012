import { styled } from "@mui/material/styles";
import Drawer, { DrawerProps } from "@mui/material/Drawer";

const ZenDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
    "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.background.default,
        overflowX: "hidden"
    },
}));

export default ZenDrawer;
