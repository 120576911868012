import { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from 'theme/TextField';
import Button from 'theme/Button';
import { useMutation, useQuery } from "@apollo/client";
import HELPDESK_MESSENGER, { HelpdeskMessengerInput, HelpdeskMessengerPayload } from "graphql/queries/HelpdeskMessengerQuery";
import UPDATE_HELPDESK_MESSENGER, { UpdateHelpdeskMessengerInput, UpdateHelpdeskMessengerPayload } from "graphql/mutations/UpdateHelpdeskMessengerMutation";
import COMMIT_DRAFT_HELPDESK_MESSENGER, { CommitDraftHelpdeskMessengerInput, CommitDraftHelpdeskMessengerPayload } from "graphql/mutations/CommitDraftHelpdeskMessengerMutation";
import TOGGLE_HELPDESK_MESSENGER, { ToggleHelpdeskMessengerInput, ToggleHelpdeskMessengerPayload } from "graphql/mutations/ToggleHelpdeskMessengerMutation";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import { useForm, Controller, useWatch } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import HelpdeskMessenger from "types/HelpdeskMessenger";
import FormHelperText from '@mui/material/FormHelperText';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import useDebounce from "hooks/useDebounce";
import useScrollPosition from "hooks/useScrollPosition";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "theme/Checkbox";
import ColorPickerModal from "./ColorPickerModal";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { darken } from '@mui/material/styles';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Drawer from 'theme/Drawer';
import HelpdeskOptionsPanel from "./HelpdeskOptionsPanel";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

const patternGivenIndex = (index: number, backgroundColor: string) => {
    switch (index) {
        case 0: {
            return "";
        }
        case 1: {
            return wavyPatternImage(backgroundColor);
        }
        case 2: {
            return texturePatternImage(backgroundColor);
        }
        case 3: {
            return brickPatternImage(backgroundColor);
        }
        case 4: {
            return bankPatternImage(backgroundColor);
        }
        default: {
            return "";
        }
    }
};

const NoPatternAvatar = styled('div')(({ theme }) => ({
    height: 80,
    width: 80,
    borderRadius: 40,
    border: `1px solid ${theme.palette.border.main}`
}));

const wavyPatternImage = (backgroundColor: string) => {
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='${darken(backgroundColor, 0.3).replace("#", "%23")}' fill-opacity='0.4' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E")`
};
const WavyPatternAvatar: any = styled('div')((props: any) => ({
    height: 80,
    width: 80,
    borderRadius: 40,
    border: !!props.selected ? '1px solid #000' : "none",
    backgroundColor: props.backgroundColor,
    backgroundImage: wavyPatternImage(props.backgroundColor),
}));

const texturePatternImage = (backgroundColor: string) => {
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='${darken(backgroundColor, 0.3).replace("#", "%23")}' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`
}
const TexturePatternAvatar: any = styled('div')((props: any) => ({
    height: 80,
    width: 80,
    borderRadius: 40,
    border: !!props.selected ? '1px solid #000' : "none",
    backgroundColor: props.backgroundColor,
    backgroundImage: texturePatternImage(props.backgroundColor),
}));

const brickPatternImage = (backgroundColor: string) => {
    return `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='${darken(backgroundColor, 0.3).replace("#", "%23")}' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
};
const BrickPatternAvatar: any = styled('div')((props: any) => ({
    height: 80,
    width: 80,
    borderRadius: 40,
    border: !!props.selected ? '1px solid #000' : "none",
    backgroundColor: props.backgroundColor,
    backgroundImage: brickPatternImage(props.backgroundColor),
}));

const bankPatternImage = (backgroundColor: string) => {
    return `url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='${darken(backgroundColor, 0.3).replace("#", "%23")}' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")`
}
const BanknotePatternAvatar: any = styled('div')((props: any) => ({
    height: 80,
    width: 80,
    borderRadius: 40,
    border: !!props.selected ? '1px solid #000' : "none",
    backgroundColor: props.backgroundColor,
    backgroundImage: bankPatternImage(props.backgroundColor)
}));

export default function Wrapper(props: { id: string }) {
    const { id } = props;

    const { data, refetch } = useQuery<HelpdeskMessengerPayload, HelpdeskMessengerInput>(HELPDESK_MESSENGER, {
        variables: { id: id, defaultRoute: "/" },
    });

    if (!!data) {
        return <EditHelpdeskMessengerPanel payload={data} id={id} refetch={refetch} />
    } else {
        return null;
    }
};

function PreviewIframe(props: { srcDoc: string, previewKey: string }) {

    const theme = useTheme();
    const { srcDoc, previewKey } = props;

    return (
        <iframe
            id="helpdesk-widget-iframe"
            key={previewKey}
            style={{
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius,
                borderWidth: "1px",
                borderStyle: `solid`,
                borderColor: theme.palette.divider,
                width: "100%",
                height: "700px",
            }}
            sandbox="allow-scripts allow-popups allow-same-origin"
            srcDoc={srcDoc}>
        </iframe>
    );
};

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function EditHelpdeskMessengerPanel(props: { payload: HelpdeskMessengerPayload, id: string, refetch: (variables: HelpdeskMessengerInput) => void }) {

    const { payload, id, refetch } = props;

    const {
        control,
        trigger,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
    } = useForm();

    const [defaultRoute, setDefaultRoute] = useState<string>("/");
    const [expanded, setExpanded] = useState<string | false>(false);
    const [conversationQuestionsExpanded, setConversationQuestionsExpanded] = useState<boolean>(false);

    const scrollPosition = useScrollPosition();

    const helpdeskName: string = useWatch({ control, name: "helpdesk_name", defaultValue: payload.helpdeskMessenger?.name });
    const debouncedHelpdeskName = useDebounce<string>(helpdeskName, 100);
    const helpdeskGreetingText: string = useWatch({ control, name: "helpdesk_geeting_text", defaultValue: payload.helpdeskMessenger?.greetingText });
    const debouncedHelpdeskGreetingText = useDebounce<string>(helpdeskGreetingText, 100);
    const helpdeskContactSupportPlaceholder: string = useWatch({ control, name: "helpdesk_contact_support_placeholder", defaultValue: payload.helpdeskMessenger?.contactSupportPlaceholder });
    const debouncedHelpdeskContactSupportPlaceholder = useDebounce<string>(helpdeskContactSupportPlaceholder, 100);
    const helpdeskGreetingDescriptionHtml: string = useWatch({ control, name: "helpdesk_greeting_description_html", defaultValue: payload.helpdeskMessenger?.greetingDescriptionHtml });
    const debouncedHelpdeskGreetingDescriptionHtml = useDebounce<string>(helpdeskGreetingDescriptionHtml, 100);
    const helpdeskContactSupportSend: string = useWatch({ control, name: "helpdesk_contact_support_send", defaultValue: payload.helpdeskMessenger?.contactSupportSend });
    const debouncedHelpdeskContactSupportSend = useDebounce<string>(helpdeskContactSupportSend, 100);
    const helpdeskContactSupportEmail: string = useWatch({ control, name: "helpdesk_contact_support_email", defaultValue: payload.helpdeskMessenger?.contactSupportEmail });
    const debouncedHelpdeskContactSupportEmail = useDebounce<string>(helpdeskContactSupportEmail, 100);
    const helpdeskThankyouTitle: string = useWatch({ control, name: "helpdesk_thankyou_title", defaultValue: payload.helpdeskMessenger?.thankyouTitle });
    const debouncedHelpdeskThankyouTitle = useDebounce<string>(helpdeskThankyouTitle, 100);
    const helpdeskThankyouMessageHtml: string = useWatch({ control, name: "helpdesk_thankyou_message_html", defaultValue: payload.helpdeskMessenger?.thankyouMessageHtml });
    const debouncedHelpdeskThankyouMessageHtml = useDebounce<string>(helpdeskThankyouMessageHtml, 100);
    const helpdeskHaveAnotherQuestionText: string = useWatch({ control, name: "helpdesk_have_another_question_text", defaultValue: payload.helpdeskMessenger?.haveAnotherQuestionText });
    const debouncedHelpdeskHaveAnotherQuestionText = useDebounce<string>(helpdeskHaveAnotherQuestionText, 100);
    const helpdeskSpecialNoticeShown: boolean = useWatch({ control, name: "helpdesk_special_notice_shown", defaultValue: payload.helpdeskMessenger?.specialNoticeShown });
    const debouncedHelpdeskSpecialNoticeShown = useDebounce<boolean>(helpdeskSpecialNoticeShown, 100);
    const helpdeskSpecialNoticeText: string = useWatch({ control, name: "helpdesk_have_another_question_text", defaultValue: payload.helpdeskMessenger?.specialNoticeText });
    const debouncedHelpdeskSpecialNoticeText = useDebounce<string>(helpdeskSpecialNoticeText, 100);
    const helpdeskBackgroundColor: string = useWatch({ control, name: "helpdesk_background_color", defaultValue: payload.helpdeskMessenger?.backgroundColor });
    const debouncedHelpdeskBackgroundColor = useDebounce<string>(helpdeskBackgroundColor, 100);
    const helpdeskActionColor: string = useWatch({ control, name: "helpdesk_action_color", defaultValue: payload.helpdeskMessenger?.actionColor });
    const debouncedHelpdeskActionColor = useDebounce<string>(helpdeskActionColor, 100);
    const helpdeskBackgroundPattern: string | undefined = useWatch({ control, name: "helpdesk_background_pattern", defaultValue: payload.helpdeskMessenger?.backgroundPattern });
    const debouncedHelpdeskBackgroundPattern = useDebounce<string | undefined>(helpdeskBackgroundPattern, 100);
    const helpdeskBackgroundPatternSelection: number = useWatch({ control, name: "helpdesk_background_pattern_selection", defaultValue: payload.helpdeskMessenger?.backgroundPatternSelection });
    const debouncedHelpdeskBackgroundPatternSelection = useDebounce<number>(helpdeskBackgroundPatternSelection, 100);
    const helpdeskFAQEnabled: boolean = useWatch({ control, name: "helpdesk_faq_enabled", defaultValue: payload.helpdeskMessenger?.faqEnabled });
    const debouncedHelpdeskFAQEnabled = useDebounce<boolean>(helpdeskFAQEnabled, 100);
    const helpdeskFAQSuggestionsEnabled: boolean = useWatch({ control, name: "helpdesk_faq_suggestions_enabled", defaultValue: payload.helpdeskMessenger?.faqSuggestionsEnabled });
    const debouncedHelpdeskFAQSuggestionsEnabled = useDebounce<boolean>(helpdeskFAQSuggestionsEnabled, 100);
    const helpdeskDarkmodeSupported: boolean = useWatch({ control, name: "helpdesk_darkmode_supported", defaultValue: payload.helpdeskMessenger?.darkmodeSupported });
    const debouncedHelpdeskDarkmodeSupported = useDebounce<boolean>(helpdeskDarkmodeSupported, 100);
    const helpdeskPromptForHelpdeskOptions: boolean = useWatch({ control, name: "helpdesk_prompt_for_helpdesk_options", defaultValue: payload.helpdeskMessenger?.promptForHelpdeskOptions });
    const debouncedHelpdeskPromptForHelpdeskOptions = useDebounce<boolean>(helpdeskPromptForHelpdeskOptions, 100);

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [previewKey, setPreviewKey] = useState<string>(uuidv4());
    const [changed, setChanged] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const [updateHelpdeskMessenger, { }] = useMutation<
        UpdateHelpdeskMessengerPayload,
        UpdateHelpdeskMessengerInput
    >(UPDATE_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });

    const [commitDraftHelpdeskMessenger, { }] = useMutation<
        CommitDraftHelpdeskMessengerPayload,
        CommitDraftHelpdeskMessengerInput
    >(COMMIT_DRAFT_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });


    const [toggleHelpdeskMessenger, { }] = useMutation<
        ToggleHelpdeskMessengerPayload,
        ToggleHelpdeskMessengerInput
    >(TOGGLE_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });

    const [previewAs, setPreviewAs] = useState<'desktop' | 'mobile'>('desktop');
    const [previewAsDarkmode, setPreviewAsDarkmode] = useState<boolean>(false);

    const handleNewPreviewAs = (_: React.MouseEvent<HTMLElement>, newPreviewAs: 'desktop' | 'mobile') => {
        setPreviewAs(newPreviewAs);
    };

    useEffect(() => {
        try {
            refetch({ id: id, defaultRoute: defaultRoute, previewAs: previewAsDarkmode ? "darkmode" : "lightmode" });
        } catch (error) {
            setAlertError("There was a problem fetching the latest Helpdesk preview");
        }
    }, [id, defaultRoute, previewAsDarkmode, refetch]);

    useEffect(() => {
        if (!!(payload?.helpdeskMessenger) && !payload.helpdeskMessenger.live && !expanded) {
            setExpanded("panel10");
        }
    }, [payload, expanded]);

    useEffect(() => {
        switch (expanded) {
            case "panel-automation": {
                setDefaultRoute("/contact-support");
                break;
            }
            case "panel1": {
                setDefaultRoute("/");
                break;
            }
            case "panel2": {
                setDefaultRoute("/contact-support");
                break;
            }
            case "panel3": {
                setDefaultRoute("/");
                break;
            }
            case "panel4": {
                setDefaultRoute("/thankyou");
                break;
            }
            default: {
                setDefaultRoute("/");
            }
        }
    }, [expanded]);

    useEffect(() => {

        if (!!payload?.helpdeskMessenger &&
            !!debouncedHelpdeskName &&
            !!debouncedHelpdeskGreetingText &&
            !!debouncedHelpdeskContactSupportPlaceholder &&
            !!debouncedHelpdeskGreetingDescriptionHtml &&
            !!debouncedHelpdeskContactSupportSend &&
            !!debouncedHelpdeskContactSupportEmail &&
            !!debouncedHelpdeskThankyouTitle &&
            !!debouncedHelpdeskThankyouMessageHtml &&
            !!debouncedHelpdeskHaveAnotherQuestionText &&
            !!debouncedHelpdeskSpecialNoticeText &&
            !!debouncedHelpdeskBackgroundColor &&
            !!debouncedHelpdeskActionColor
        ) {

            try {
                const helpdesk = payload!.helpdeskMessenger!;

                updateHelpdeskMessenger({
                    variables: {
                        input: {
                            id: helpdesk.id,
                            name: debouncedHelpdeskName,
                            live: helpdesk.live,
                            messengerLocation: helpdesk.messengerLocation,
                            faqEnabled: debouncedHelpdeskFAQEnabled,
                            faqSuggestionsEnabled: debouncedHelpdeskFAQSuggestionsEnabled,
                            somethingElseEnabled: helpdesk.somethingElseEnabled,
                            somethingElseText: helpdesk.somethingElseText,
                            greetingText: debouncedHelpdeskGreetingText,
                            greetingDescriptionHtml: debouncedHelpdeskGreetingDescriptionHtml,
                            needMoreHelpText: helpdesk.needMoreHelpText,
                            contactSupportTitle: debouncedHelpdeskGreetingText,
                            contactSupportPlaceholder: debouncedHelpdeskContactSupportPlaceholder,
                            contactSupportEmail: debouncedHelpdeskContactSupportEmail,
                            contactSupportSend: debouncedHelpdeskContactSupportSend,
                            thankyouTitle: debouncedHelpdeskThankyouTitle,
                            thankyouMessageHtml: debouncedHelpdeskThankyouMessageHtml,
                            haveAnotherQuestionText: debouncedHelpdeskHaveAnotherQuestionText,
                            specialNoticeShown: debouncedHelpdeskSpecialNoticeShown,
                            specialNoticeText: debouncedHelpdeskSpecialNoticeText,
                            darkmodeSupported: debouncedHelpdeskDarkmodeSupported,
                            backgroundColor: debouncedHelpdeskBackgroundColor,
                            actionColor: debouncedHelpdeskActionColor,
                            promptForHelpdeskOptions: debouncedHelpdeskPromptForHelpdeskOptions,
                            backgroundPattern: patternGivenIndex(debouncedHelpdeskBackgroundPatternSelection, debouncedHelpdeskBackgroundColor),
                            backgroundPatternSelection: debouncedHelpdeskBackgroundPatternSelection
                        }
                    }
                })
                    .then(() => {
                        setPreviewKey(uuidv4());
                    })
                    .catch(() => {
                        setAlertError("Unable to update helpdesk");
                    })

            } catch (e: unknown) {
                if (e instanceof Error) {
                    setAlertError(e.message);
                }
            }
        }
    }, [debouncedHelpdeskName,
        debouncedHelpdeskGreetingText,
        debouncedHelpdeskContactSupportPlaceholder,
        debouncedHelpdeskGreetingDescriptionHtml,
        debouncedHelpdeskContactSupportSend,
        debouncedHelpdeskContactSupportEmail,
        debouncedHelpdeskThankyouTitle,
        debouncedHelpdeskThankyouMessageHtml,
        debouncedHelpdeskHaveAnotherQuestionText,
        debouncedHelpdeskSpecialNoticeShown,
        debouncedHelpdeskSpecialNoticeText,
        debouncedHelpdeskActionColor,
        debouncedHelpdeskBackgroundColor,
        debouncedHelpdeskBackgroundPattern,
        debouncedHelpdeskBackgroundPatternSelection,
        debouncedHelpdeskFAQEnabled,
        debouncedHelpdeskFAQSuggestionsEnabled,
        debouncedHelpdeskDarkmodeSupported,
        debouncedHelpdeskPromptForHelpdeskOptions
    ]);

    const onSubmit = async () => {
        try {
            const { data } = await commitDraftHelpdeskMessenger({
                variables: {
                    input: {
                        draftHelpdeskMessengerId: id,
                    }
                }
            });

            if (!!data?.commitDraftHelpdeskMessenger.draftHelpdeskMessenger) {
                enqueueSnackbar(`Messenger saved`);
                setAlertError(undefined);
                setChanged(false);
                setPreviewKey(uuidv4());
            } else if (!!data?.commitDraftHelpdeskMessenger && data.commitDraftHelpdeskMessenger.errors.length > 0) {
                setAlertError(data.commitDraftHelpdeskMessenger.errors[0]);
            }

        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    };

    const handleAccordionChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    function renderForm(HelpdeskMessenger: HelpdeskMessenger) {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" style={{ minHeight: "calc(100vh - 120px)" }}>
                    <Stack direction="column" spacing={2} style={{ width: "50%" }} >
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <Typography variant="h6">Display on your store</Typography>
                        <div>
                            <Accordion variant="outlined" expanded={expanded === 'panel10'} onChange={handleAccordionChange('panel10')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Display Messenger on your store</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <Typography variant="subtitle2" color="text.secondary">Install the messenger to your Store theme. You can then adjust its visibility from Shopify theme settings.</Typography>
                                        <div>
                                            <Button size="small" variant="contained" color="secondary" onClick={async () => {
                                                try {
                                                    const { data } = await toggleHelpdeskMessenger({
                                                        refetchQueries: ["HelpdeskMessengers", "HelpdeskMessenger"],
                                                        variables: {
                                                            input: {
                                                                helpdeskMessengerId: id,
                                                            }
                                                        }
                                                    });

                                                    if (!!(data?.toggleHelpdeskMessenger?.helpdeskMessenger)) {
                                                        window.location.href = HelpdeskMessenger.shopifyDeepLink;
                                                    }
                                                } catch {
                                                    enqueueSnackbar(`👋 There was a problem installing the Helpdesk Messenger`);
                                                } finally {
                                                    setExpanded("panel1")
                                                }
                                            }}>Install to Shopify theme</Button>
                                        </div>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Typography variant="h6">Manage the unexpected</Typography>
                        <div>
                            <Accordion variant="outlined" expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Show a special message in your Messenger</Typography>
                                        <Typography variant="caption" color="text.secondary">Communicate about delays or high volume</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <FormControl>
                                            <Controller
                                                name="helpdesk_special_notice_shown"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.specialNoticeShown}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.primary">Display notice</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_special_notice_shown"
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_special_notice_shown", !value);
                                                                    trigger("helpdesk_special_notice_shown");
                                                                    setChanged(true);
                                                                    console.log("SPECIAL NOTICE");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                        </FormControl>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_special_notice_text"
                                                    rules={{
                                                        required: true,
                                                        maxLength: 150,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_special_notice_text"
                                                            type="helpdesk_special_notice_text"
                                                            size="small"
                                                            helperText={errors.helpdesk_special_notice_text ? errors.helpdesk_special_notice_text.message : `${150 - value.length} characters remaining`}
                                                            variant="outlined"
                                                            placeholder="Notice"
                                                            error={!!errors.helpdesk_special_notice_text}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_special_notice_text");
                                                                setChanged(true);
                                                                console.log("SPECIAL NOTICE TEXT");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.specialNoticeText}
                                                />
                                            </FormControl>
                                        </div>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Typography variant="h6">Customize the experience</Typography>
                        <div>
                            <Accordion variant="outlined" expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Set your welcome message</Typography>
                                        <Typography variant="caption" color="text.secondary">Introduce your team</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <Typography variant="subtitle2" fontWeight={600} color="text.primary">Greeting</Typography>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_geeting_text"
                                                    rules={{
                                                        required: true,
                                                        maxLength: 15,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_geeting_text"
                                                            type="helpdesk_geeting_text"
                                                            size="small"
                                                            helperText={errors.helpdesk_geeting_text ? errors.helpdesk_geeting_text.message : "Say hi to your customers when they open the Messenger."}
                                                            variant="outlined"
                                                            placeholder="Hi there"
                                                            error={!!errors.helpdesk_geeting_text}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_geeting_text");
                                                                setChanged(true);
                                                                console.log("GREETING TEXT");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.contactSupportTitle}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_greeting_description_html"
                                                    rules={{
                                                        required: true,
                                                        maxLength: 50,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_greeting_description_html"
                                                            type="helpdesk_greeting_description_html"
                                                            size="small"
                                                            helperText={errors.helpdesk_greeting_description_html ? errors.helpdesk_greeting_description_html.message : "Introduce your team and say how you can help your customer."}
                                                            variant="outlined"
                                                            placeholder="We're here to help if you have any questions."
                                                            error={!!errors.helpdesk_greeting_description_html}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_greeting_description_html");
                                                                setChanged(true);
                                                                console.log("DESCRIPTION TEXT");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.greetingDescriptionHtml}
                                                />
                                            </FormControl>
                                        </div>
                                        <Typography variant="subtitle2" fontWeight={600} color="text.primary">Customize the look and feel</Typography>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_contact_support_placeholder"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_contact_support_placeholder"
                                                            type="helpdesk_contact_support_placeholder"
                                                            size="small"
                                                            helperText={errors.helpdesk_contact_support_placeholder ? errors.helpdesk_contact_support_placeholder.message : "Placeholder for customer to enter their message"}
                                                            variant="outlined"
                                                            placeholder="Your enquiry"
                                                            error={!!errors.helpdesk_contact_support_placeholder}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_contact_support_placeholder");
                                                                setChanged(true);
                                                                console.log("CONTACT SUPPORT");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.contactSupportPlaceholder}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_contact_support_email"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_contact_support_email"
                                                            type="helpdesk_contact_support_email"
                                                            size="small"
                                                            helperText={errors.helpdesk_contact_support_email ? errors.helpdesk_contact_support_email.message : "Placeholder for customer to enter their Email"}
                                                            variant="outlined"
                                                            placeholder="Email"
                                                            error={!!errors.helpdesk_contact_support_email}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_contact_support_email");
                                                                setChanged(true);
                                                                console.log("CONTACT SUPPORT EMAIL");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.contactSupportEmail}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_contact_support_send"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_contact_support_send"
                                                            type="helpdesk_contact_support_send"
                                                            size="small"
                                                            helperText={errors.helpdesk_contact_support_send ? errors.helpdesk_contact_support_send.message : "The send message button"}
                                                            variant="outlined"
                                                            placeholder="Send"
                                                            error={!!errors.helpdesk_contact_support_send}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_contact_support_send");
                                                                setChanged(true);
                                                                console.log("CONTACT SUPPORT SEND");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.contactSupportSend}
                                                />
                                            </FormControl>
                                        </div>
                                        <Typography variant="subtitle2" fontWeight={600} color="text.primary">Pro features</Typography>
                                        <FormControl>
                                            <Controller
                                                name="helpdesk_powered_by_zenshop_enabled"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.faqEnabled}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.secondary">Show a discreet message about zenshop</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_powered_by_zenshop_enabled"
                                                                disabled
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_powered_by_zenshop", !value);
                                                                    if ((!value) == false) {
                                                                        setFormValue("helpdesk_powered_by_zenshop_enabled", false);
                                                                        trigger("helpdesk_powered_by_zenshop_enabled");
                                                                    }
                                                                    trigger("helpdesk_powered_by_zenshop_enabled");
                                                                    setChanged(true);
                                                                    console.log("Powered true");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion variant="outlined" expanded={expanded === 'panel-automation'} onChange={handleAccordionChange('panel-automation')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Get context about the conversation</Typography>
                                        <Typography variant="caption" color="text.secondary">Automate asking questions before conversations reach the support team</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <div>
                                            <Button onClick={() => {
                                                window.open("https://support.zenshop.app/using-messenger/prompt-customers-for-information", "_blank");
                                            }} size="small" color="inherit" variant="text" startIcon={<OpenInNewIcon />}>See our guide on getting started with conversation topics</Button>
                                        </div>
                                        <div>
                                            <Button size="small" color="inherit" variant="text" startIcon={<QuestionAnswerOutlinedIcon />} onClick={() => { setConversationQuestionsExpanded(true); }}>Open topics</Button>
                                        </div>
                                        <FormControl>
                                            <Controller
                                                name="helpdesk_prompt_for_helpdesk_options"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.promptForHelpdeskOptions}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.primary">Prompt customers to select a topic before starting a conversation</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_prompt_for_helpdesk_options"
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_prompt_for_helpdesk_options", !value);
                                                                    if ((!value) == false) {
                                                                        setFormValue("helpdesk_prompt_for_helpdesk_options", false);
                                                                        trigger("helpdesk_prompt_for_helpdesk_options");
                                                                    }
                                                                    trigger("helpdesk_prompt_for_helpdesk_options");
                                                                    setChanged(true);
                                                                    console.log("Prompt for helpdesk options true");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion variant="outlined" expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Set your thank you message</Typography>
                                        <Typography variant="caption" color="text.secondary">Let your customers know their message was received</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <Typography variant="subtitle2" fontWeight={600} color="text.primary">Thank you message</Typography>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_thankyou_message_html"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_thankyou_message_html"
                                                            type="helpdesk_thankyou_message_html"
                                                            size="small"
                                                            helperText={errors.helpdesk_thankyou_message_html ? errors.helpdesk_thankyou_message_html.message : "Set expectations that the message will be dealt with"}
                                                            variant="outlined"
                                                            placeholder="Your message was received, check your inbox later, we'll reply as soon as possible."
                                                            error={!!errors.helpdesk_thankyou_message_html}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_thankyou_message_html");
                                                                setChanged(true);
                                                                console.log("THANKYOU MESSAGE");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.thankyouMessageHtml}
                                                />
                                            </FormControl>
                                        </div>
                                        <Typography variant="subtitle2" fontWeight={600} color="text.primary">Customize the look and feel</Typography>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_thankyou_title"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_thankyou_title"
                                                            type="helpdesk_thankyou_title"
                                                            size="small"
                                                            helperText={errors.helpdesk_thankyou_title ? errors.helpdesk_thankyou_title.message : "The main title"}
                                                            variant="outlined"
                                                            placeholder="Thank you"
                                                            error={!!errors.helpdesk_thankyou_title}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_thankyou_title");
                                                                setChanged(true);
                                                                console.log("THANKYOU TITLE");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.thankyouTitle}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ maxWidth: 400 }}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name="helpdesk_have_another_question_text"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="helpdesk_have_another_question_text"
                                                            type="helpdesk_have_another_question_text"
                                                            size="small"
                                                            helperText={errors.helpdesk_have_another_question_text ? errors.helpdesk_have_another_question_text.message : "The have another question button"}
                                                            variant="outlined"
                                                            placeholder="Have another question?"
                                                            error={!!errors.helpdesk_have_another_question_text}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                trigger("helpdesk_have_another_question_text");
                                                                setChanged(true);
                                                                console.log("ANOTHER QUESTION");
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={HelpdeskMessenger.haveAnotherQuestionText}
                                                />
                                            </FormControl>
                                        </div>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion variant="outlined" expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Style your Messenger</Typography>
                                        <Typography variant="caption" color="text.secondary">Customize your Messenger to your brand</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <Stack>
                                            <Typography variant="subtitle2" fontWeight={600} color="text.primary">Background color</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">Used behind your profile and other backgrounds.</Typography>
                                        </Stack>
                                        <ColorPickerModal id="background-color-picker" defaultValue={HelpdeskMessenger.backgroundColor} onChange={(color) => {
                                            setFormValue("helpdesk_background_color", color);
                                            trigger("helpdesk_background_color");
                                            setChanged(true);
                                            console.log("COLOR PICKER BG");
                                        }} />
                                        <Stack>
                                            <Typography variant="subtitle2" fontWeight={600} color="text.primary">Action color</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">Used in buttons, links and more to highlight and emphasize.</Typography>
                                        </Stack>
                                        <ColorPickerModal id="action-color-picker" defaultValue={HelpdeskMessenger.actionColor} onChange={(color) => {
                                            setFormValue("helpdesk_action_color", color);
                                            trigger("helpdesk_action_color");
                                            setChanged(true);
                                            console.log("COLOR PICKER ACTION");
                                        }} />
                                        <Stack>
                                            <Typography variant="subtitle2" fontWeight={600} color="text.primary">Background pattern</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">Adds a textured pattern above the background color.</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <NoPatternAvatar onClick={() => {
                                                setFormValue("helpdesk_background_pattern_selection", 0);
                                                setFormValue("helpdesk_background_pattern", "");
                                                trigger("helpdesk_background_pattern");
                                                setChanged(true);
                                                console.log("No Pattern true");
                                            }} />
                                            <WavyPatternAvatar backgroundColor={helpdeskBackgroundColor} onClick={() => {
                                                setFormValue("helpdesk_background_pattern_selection", 1);
                                                setFormValue("helpdesk_background_pattern", wavyPatternImage(helpdeskBackgroundColor));
                                                trigger("helpdesk_background_pattern");
                                                setChanged(true);
                                                console.log("Wavy Pattern true");
                                            }} />
                                            <TexturePatternAvatar backgroundColor={helpdeskBackgroundColor} onClick={() => {
                                                setFormValue("helpdesk_background_pattern_selection", 2);
                                                setFormValue("helpdesk_background_pattern", texturePatternImage(helpdeskBackgroundColor));
                                                trigger("helpdesk_background_pattern");
                                                setChanged(true);
                                                console.log("Texture Pattern true");
                                            }} />
                                            <BrickPatternAvatar backgroundColor={helpdeskBackgroundColor} onClick={() => {
                                                setFormValue("helpdesk_background_pattern_selection", 3);
                                                setFormValue("helpdesk_background_pattern", brickPatternImage(helpdeskBackgroundColor));
                                                trigger("helpdesk_background_pattern");
                                                setChanged(true);
                                                console.log("Brick Pattern true");
                                            }} />
                                            <BanknotePatternAvatar backgroundColor={helpdeskBackgroundColor} onClick={() => {
                                                setFormValue("helpdesk_background_pattern_selection", 4);
                                                setFormValue("helpdesk_background_pattern", bankPatternImage(helpdeskBackgroundColor));
                                                trigger("helpdesk_background_pattern");
                                                setChanged(true);
                                                console.log("Bank Pattern true");
                                            }} />
                                        </Stack>
                                        <Stack>
                                            <Typography variant="subtitle2" fontWeight={600} color="text.primary">Darkmode</Typography>
                                            <Typography variant="subtitle2" color="text.secondary">Support darkmode when the user prefers a dark environment.</Typography>
                                        </Stack>
                                        <FormControl>
                                            <Controller
                                                name="helpdesk_darkmode_supported"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.darkmodeSupported}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.primary">Enable darkmode</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_darkmode_supported"
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_darkmode_supported", !value);
                                                                    trigger("helpdesk_darkmode_supported");
                                                                    setChanged(true);
                                                                    console.log("Darlmode true");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion variant="outlined" expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction="column">
                                        <Typography variant="subtitle2" fontWeight={600}>Integrate Articles with your Messenger</Typography>
                                        <Typography variant="caption" color="text.secondary">Answer questions before they reach the support team</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" p={1} spacing={2}>
                                        <div>
                                            <Button onClick={() => {
                                                window.open("https://support.zenshop.app/using-messenger/setup-faq-and-articles", "_blank");

                                            }}
                                                size="small" color="inherit" variant="text" startIcon={<OpenInNewIcon />}>See our guide on getting started with articles</Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => history.push("/articles?selection=articles")} size="small" color="inherit" variant="text" startIcon={<MenuBookIcon />}>Open articles</Button>
                                        </div>
                                        <FormControl>
                                            <Controller
                                                name="helpdesk_faq_enabled"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.faqEnabled}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.primary">Let customers search for articles</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_faq_enabled"
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_faq_enabled", !value);
                                                                    if ((!value) == false) {
                                                                        setFormValue("helpdesk_faq_suggestions_enabled", false);
                                                                        trigger("helpdesk_faq_suggestions_enabled");
                                                                    }
                                                                    trigger("helpdesk_faq_enabled");
                                                                    setChanged(true);
                                                                    console.log("FAQ true");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                        </FormControl>
                                        {!!debouncedHelpdeskFAQEnabled && <FormControl>
                                            <Controller
                                                name="helpdesk_faq_suggestions_enabled"
                                                control={control}
                                                defaultValue={HelpdeskMessenger.faqSuggestionsEnabled}
                                                render={({ field: { value } }) => (
                                                    <FormControlLabel
                                                        label={<Typography variant="subtitle2" fontWeight={600} color="text.primary">Suggest articles to customers</Typography>}
                                                        control={
                                                            <Checkbox
                                                                id="helpdesk_faq_suggestions_enabled"
                                                                sx={{ ml: 1 }}
                                                                onChange={() => {
                                                                    setFormValue("helpdesk_faq_suggestions_enabled", !value);
                                                                    trigger("helpdesk_faq_suggestions_enabled");
                                                                    setChanged(true);
                                                                    console.log("FAQ suugestions true");
                                                                }}
                                                                value={value}
                                                                checked={value === true} />
                                                        } />
                                                )}
                                            />
                                            <FormHelperText sx={{ pl: 3 }}>Highlight your most popular articles.</FormHelperText>
                                        </FormControl>}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Stack>
                    <Stack sx={{ position: "relative" }}>
                        <Stack spacing={2} direction="column" style={{ position: "absolute", top: `${(Math.max(scrollPosition - 80, 0))}px`, width: previewAs === 'mobile' ? '400px' : '600px' }} sx={{ pl: 1 }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <div>
                                    <Button disabled={!changed} size="small" type="submit" fullWidth color="success" variant="contained" onClick={() => { }}>Save changes</Button>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <ToggleButtonGroup size="small" value={previewAsDarkmode ? "darkmode" : "lightmode"} onChange={() => {
                                    setPreviewAsDarkmode(!previewAsDarkmode);
                                }}>
                                    {!previewAsDarkmode && <ToggleButton value="darkmode">
                                        <LightModeIcon />
                                    </ToggleButton>}
                                    {!!previewAsDarkmode && <ToggleButton value="lightmode">
                                        <DarkModeIcon />
                                    </ToggleButton>}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup value={previewAs} exclusive onChange={handleNewPreviewAs} size="small">
                                    <ToggleButton value="desktop">
                                        <DesktopWindowsIcon />
                                    </ToggleButton>
                                    <ToggleButton value="mobile">
                                        <PhoneIphoneIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            {!!changed && <Alert variant="filled" color="warning" icon={false}><Typography fontWeight={600} variant="body2" color="text.primary">You have unsaved changes to save</Typography></Alert>}
                            <PreviewIframe previewKey={previewKey} srcDoc={payload?.helpdeskMessenger?.previewHtml || ""} />
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        );
    }

    return (
        <Stack direction="column">
            {!!(payload?.helpdeskMessenger) && renderForm(payload.helpdeskMessenger!)}
            {!!(payload?.helpdeskMessenger) && <Drawer anchor="left" open={conversationQuestionsExpanded} onClose={() => { setConversationQuestionsExpanded(false); }}>
                <HelpdeskOptionsPanel id={id} helpdesk={payload.helpdeskMessenger} onClose={() => { setConversationQuestionsExpanded(false); }} />
            </Drawer>}
        </Stack>
    );
}
