import { gql } from "@apollo/client";
import Article from "types/Article";
import ArticleFragment from "../fragments/ArticleFragment";

export interface ArticleInput {
  id: string;
}

export interface ArticlePayload {
  article: Article;
}

const ARTICLE = gql`
  query Article($id: ID!) {
    article(id: $id) {
        ...ArticleFields
        contentHtml
        author {
          id
          name
          avatarUrl
        }
    }
  }
  ${ArticleFragment},
`;

export default ARTICLE;