import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "../fragments/HelpdeskMessengerFragment";
import ShopifyIntegrationFragment from "graphql/fragments/ShopifyIntegrationFragment";

export interface HelpdeskMessengersSortInput {
  name?: "ASC" | "DESC",
}

export interface HelpdeskMessengersInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: HelpdeskMessengersFilterInput;
}
export interface HelpdeskMessengersFilterInput {
  sort?: HelpdeskMessengersSortInput;
}

export interface HelpdeskMessengersPayload {
  helpdeskMessengers: {
    pageInfo: {
      startCursor: string;
      endCursor: string;
    };
    nodes: HelpdeskMessenger[];
  };
}

const HELPDESK_MESSENGERS = gql`
  query HelpdeskMessengers(
    $after: String
    $before: String
    $last: Int
    $first: Int,
  ) {
    helpdeskMessengers(
      first: $first
      last: $last
      after: $after
      before: $before,
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      nodes {
          ...HelpdeskMessengerFields
          shopifyIntegration {
            ...ShopifyIntegrationFields
          }
      }
    }
  }
  ${HelpdeskMessengerFragment},
  ${ShopifyIntegrationFragment}
`;

export default HELPDESK_MESSENGERS;