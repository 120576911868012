import { gql } from "@apollo/client";
import ConversationType from "types/ConversationType";
import ConversationTypeFragment from "../fragments/ConversationTypeFragment";

export interface CreateConversationTypeInput {
  input: {
    title: string;
    descriptionHtml: string;
  };
}

export interface CreateConversationTypePayload {
  createConversationType: {
    errors: string[];
    conversationType?: ConversationType;
  };
}

const CREATE_CONVERSATION_TYPE = gql`
  mutation CreateConversationType($input: CreateConversationTypeMutationInput!) {
    createConversationType(input: $input) {
      errors
      conversationType {
        ...ConversationTypeFields
      }
    }
  }
  ${ConversationTypeFragment}
`;

export default CREATE_CONVERSATION_TYPE;