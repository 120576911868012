import { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'theme/Button';
import Card from 'theme/Card';
import { useForm, Controller } from "react-hook-form";
import Alert from '@mui/material/Alert';
import TextField from 'theme/TextField';
import FormControl from "@mui/material/FormControl";
import Divider from "theme/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import LABELS, { LabelsInput, LabelsPayload } from "graphql/queries/LabelsQuery";
import HELPDESK_MESSENGER_OPTION, { HelpdeskMessengerOptionInput, HelpdeskMessengerOptionPayload } from "graphql/queries/HelpdeskMessengerOptionQuery";
import CONVERSATION_TYPE, { ConversationTypeInput, ConversationTypePayload } from "graphql/queries/ConversationTypeQuery";
import ARTICLE, { ArticleInput, ArticlePayload } from "graphql/queries/ArticleQuery";
import COMMIT_DRAFT_HELPDESK_MESSENGER, { CommitDraftHelpdeskMessengerInput, CommitDraftHelpdeskMessengerPayload } from "graphql/mutations/CommitDraftHelpdeskMessengerMutation";
import UPDATE_HELPDESK_MESSENGER_OPTION, { UpdateHelpdeskMessengerOptionInput, UpdateHelpdeskMessengerOptionPayload } from "graphql/mutations/UpdateHelpdeskMessengerOptionMutation";
import DELETE_HELPDESK_MESSENGER_OPTION, { DeleteHelpdeskMessengerOptionInput, DeleteHelpdeskMessengerOptionPayload } from "graphql/mutations/DeleteHelpdeskMessengerOptionMutation";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from 'notistack';
import ChooseConversationTypeDialog from "./ChooseConversationTypeDialog";
import ChooseLabelsDialog from "../../../labels/ChooseLabelsDialog";
import { renderLabel } from "components/conversations/renderers/RenderLabel";
import HelpdeskMessengerOption from "types/HelpdeskMessengerOption";
import ChooseArticleDialog from "./ChooseArticleDialog";

function ConversationTypeButton(props: { id: string, onClick: () => void }) {
    const { onClick, id } = props;
    const { data } = useQuery<ConversationTypePayload, ConversationTypeInput>(CONVERSATION_TYPE, {
        variables: {
            id: id
        },
    });
    return (
        <Stack direction="column" alignItems="flex-start">
            <Button onClick={onClick}>Conversation type</Button>
            <Button sx={{ p: 1, pl: 2, pr: 2 }} onClick={onClick}>
                <Typography variant="subtitle2" fontWeight={600}>{data?.conversationType.title}</Typography>
            </Button>
        </Stack>
    );
}

function ArticleButton(props: { id: string, onClick: () => void }) {
    const { onClick, id } = props;
    const { data } = useQuery<ArticlePayload, ArticleInput>(ARTICLE, {
        variables: {
            id: id
        },
    });
    return (
        <Stack direction="column" alignItems="flex-start">
            <Button onClick={onClick}>Suggested article</Button>
            <Button sx={{ p: 1, pl: 2, pr: 2 }} onClick={onClick}>
                <Typography variant="subtitle2" fontWeight={600}>{data?.article?.title}</Typography>
            </Button>
        </Stack>
    );
}

function SelectedLabelsButtons(props: { selectedLabels: string[], onClick: () => void }) {
    const { onClick, selectedLabels } = props;
    const { data } = useQuery<LabelsPayload, LabelsInput>(LABELS, { variables: { filters: { sort: { title: "ASC" } } }, });
    const labels = data?.labels?.nodes.filter((label) => selectedLabels.includes(label.id)) || [];
    return <Box p={1} pl={2} pr={2} onClick={onClick}>{renderLabel({ value: labels } as any)}</Box>;
}

function EditHelpdeskMessengerOptionPanel(props: { helpdeskMessengerOption: HelpdeskMessengerOption, draftHelpdeskMessengerId: string, onCancel: () => void }) {

    const { onCancel, helpdeskMessengerOption, draftHelpdeskMessengerId } = props;

    const [updateHelpdeskMessengerOption, { }] = useMutation<
        UpdateHelpdeskMessengerOptionPayload,
        UpdateHelpdeskMessengerOptionInput
    >(UPDATE_HELPDESK_MESSENGER_OPTION, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });

    const [deleteHelpdeskMessengerOption, { }] = useMutation<
        DeleteHelpdeskMessengerOptionPayload,
        DeleteHelpdeskMessengerOptionInput
    >(DELETE_HELPDESK_MESSENGER_OPTION, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });

    const [commitDraftHelpdeskMessenger, { }] = useMutation<
        CommitDraftHelpdeskMessengerPayload,
        CommitDraftHelpdeskMessengerInput
    >(COMMIT_DRAFT_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessenger", "HelpdeskMessengers"] });

    const { enqueueSnackbar } = useSnackbar();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [conversationTypesOpen, setConversationTypesOpen] = useState<boolean>(false);
    const [selectedConversationTypeId, setSelectedConversationTypeId] = useState<string | undefined>(helpdeskMessengerOption.conversationType?.id);

    const [labelsOpen, setLabelsOpen] = useState<boolean>(false);
    const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>(helpdeskMessengerOption.labels.nodes.map((label) => label.id));

    const [articleOpen, setArticleOpen] = useState<boolean>(false);
    const [selectedArticleId, setSelectedArticleId] = useState<string | undefined>(helpdeskMessengerOption.article?.id);

    const {
        control,
        handleSubmit,
        getValues: getFormValues,
        setValue: setFormValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setFormValue("helpdesk_messenger_option_question_text", helpdeskMessengerOption.questionText);
        setSelectedConversationTypeId(helpdeskMessengerOption.conversationType?.id);
        setSelectedLabelIds(helpdeskMessengerOption.labels.nodes.map((label) => label.id));
        setSelectedArticleId(helpdeskMessengerOption.article?.id);
    }, [helpdeskMessengerOption]);

    const onSubmit = async () => {
        try {
            const { data: createData } = await updateHelpdeskMessengerOption({
                refetchQueries: ["HelpdeskMessengers", "HelpdeskMessenger"],
                variables: {
                    input: {
                        id: helpdeskMessengerOption.id,
                        questionText: getFormValues("helpdesk_messenger_option_question_text"),
                        labelIds: selectedLabelIds,
                        conversationTypeId: selectedConversationTypeId,
                        articleId: selectedArticleId,
                    }
                }
            });

            const { data: commitData } = await commitDraftHelpdeskMessenger({
                variables: {
                    input: {
                        draftHelpdeskMessengerId: draftHelpdeskMessengerId,
                    }
                }
            });

            if (!!(createData?.updateHelpdeskMessengerOption?.helpdeskMessenger) && !!(commitData?.commitDraftHelpdeskMessenger?.draftHelpdeskMessenger)) {
                enqueueSnackbar(`Saved`);
                setAlertError(undefined);
                onCancel();
            } else if (!!createData?.updateHelpdeskMessengerOption && createData.updateHelpdeskMessengerOption.errors.length > 0) {
                setAlertError(createData.updateHelpdeskMessengerOption.errors[0]);
            }

        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    };

    const onDelete = async () => {
        try {
            const { data: deleteData } = await deleteHelpdeskMessengerOption({
                refetchQueries: ["HelpdeskMessengers", "HelpdeskMessenger"],
                variables: {
                    input: {
                        id: helpdeskMessengerOption.id,
                    }
                }
            });

            const { data: commitData } = await commitDraftHelpdeskMessenger({
                variables: {
                    input: {
                        draftHelpdeskMessengerId: draftHelpdeskMessengerId,
                    }
                }
            });

            if (!!(deleteData?.deleteHelpdeskMessengerOption?.ok) && !!(commitData?.commitDraftHelpdeskMessenger?.draftHelpdeskMessenger)) {
                enqueueSnackbar(`Deleted`);
                setAlertError(undefined);
                onCancel();
            } else if (!!deleteData?.deleteHelpdeskMessengerOption && deleteData.deleteHelpdeskMessengerOption.errors.length > 0) {
                setAlertError(deleteData.deleteHelpdeskMessengerOption.errors[0]);
            }

        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    }

    return (
        <>
            {!!alertError && <Alert color="error" icon={false}>{alertError}</Alert>}
            <ChooseConversationTypeDialog open={conversationTypesOpen} selectedConversationTypeId={selectedConversationTypeId} onClose={(returnedSelectionId) => {
                setSelectedConversationTypeId(returnedSelectionId);
                setConversationTypesOpen(false);
            }} />
            <ChooseLabelsDialog open={labelsOpen} selectedLabelIds={selectedLabelIds} onClose={(returnedSelectionIds) => {
                setSelectedLabelIds(returnedSelectionIds);
                setLabelsOpen(false);
            }} />
            <ChooseArticleDialog open={articleOpen} selectedArticleId={selectedArticleId} onClose={(returnedSelectionId) => {
                setSelectedArticleId(returnedSelectionId);
                setArticleOpen(false);
            }} />
            <Card variant="outlined">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="column" p={2} spacing={2}>
                        <Typography pl={1} variant="h5">Edit topic</Typography>
                        <Divider />
                        <div style={{ maxWidth: 400 }}>
                            <FormControl fullWidth variant="outlined">
                                <Controller
                                    name="helpdesk_messenger_option_question_text"
                                    rules={{
                                        required: "Please enter a commonly asked question",
                                        maxLength: 50,
                                    }}
                                    defaultValue={helpdeskMessengerOption.questionText}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id="helpdesk_messenger_option_question_text"
                                            type="helpdesk_messenger_option_question_text"
                                            size="small"
                                            helperText={errors.helpdesk_messenger_option_question_text ? errors.helpdesk_messenger_option_question_text.message : "Use a commonly asked question by customers to set the context of the conversation"}
                                            variant="outlined"
                                            placeholder="A commonly asked question"
                                            error={!!errors.helpdesk_messenger_option_question_text}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                />
                            </FormControl>
                        </div>
                        <div style={{ maxWidth: 400 }}>
                            {!selectedArticleId && <Stack direction="column" alignItems="flex-start">
                                <Button onClick={() => setArticleOpen(true)}>Suggested article</Button>
                                <Button sx={{ p: 1, pl: 2, pr: 2 }} onClick={() => setArticleOpen(true)}>
                                    <Typography variant="subtitle2" fontWeight={600}>Unassigned</Typography>
                                </Button>
                            </Stack>}
                            {!!selectedArticleId && <ArticleButton id={selectedArticleId} onClick={() => setArticleOpen(true)} />}
                            <FormHelperText sx={{ pl: 2 }}>Answer the question before it reaches your inbox</FormHelperText>
                        </div>
                        <div style={{ maxWidth: 400 }}>
                            <Button onClick={() => setLabelsOpen(true)}>Assign labels to the conversation</Button>
                            {selectedLabelIds.length > 0 && <SelectedLabelsButtons onClick={() => setLabelsOpen(true)} selectedLabels={selectedLabelIds} />}
                            <FormHelperText sx={{ pl: 2 }}>Any incoming conversations with this topic will be automatically labeled</FormHelperText>
                        </div>
                        {helpdeskMessengerOption.deletable && <Divider />}
                        {helpdeskMessengerOption.deletable && <Typography pl={1} variant="subtitle2" fontWeight={600}>Danger zone</Typography>}
                        {helpdeskMessengerOption.deletable && <div>
                            <Button sx={{ ml: 2 }} size="small" variant="contained" color="error" onClick={() => onDelete()}>Delete topic</Button>
                        </div>}
                        <Stack direction="column" spacing={1}>
                            <Divider />
                            <Stack direction="row" justifyContent="flex-end" p={1} pb={0} spacing={1}>
                                <Button size="small" color="inherit" onClick={onCancel}>Cancel</Button>
                                <Button size="small" variant="contained" color="success" type="submit">Save</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            </Card>
        </>
    );
}

export default function EditHelpdeskMessengerOptionPanelContainer(props: { id: string, draftHelpdeskMessengerId: string, onCancel: () => void }) {

    const { onCancel, id, draftHelpdeskMessengerId } = props;

    const { data: helpdeskOptionData, refetch } = useQuery<HelpdeskMessengerOptionPayload, HelpdeskMessengerOptionInput>(HELPDESK_MESSENGER_OPTION, {
        variables: {
            id: id
        },
    });

    useEffect(() => {
        refetch({ id: id });
    }, [id]);

    if (!!(helpdeskOptionData?.helpdeskMessengerOption)) {
        return <EditHelpdeskMessengerOptionPanel helpdeskMessengerOption={helpdeskOptionData.helpdeskMessengerOption} draftHelpdeskMessengerId={draftHelpdeskMessengerId} onCancel={onCancel} />
    } else {
        return <div />;
    }
}
