import { gql } from "@apollo/client";
import Article from "types/Article";
import ArticleFragment from "../fragments/ArticleFragment";

export interface ArticlesSortInput {
  id?: "ASC" | "DESC",
  title?: "ASC" | "DESC",
  state?: "ASC" | "DESC",
  author?: "ASC" | "DESC",
}

export interface ArticlesFilterInput {
  searchQuery?: string;
  sort?: ArticlesSortInput;
  live?: boolean;
}

export interface ArticlesInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: ArticlesFilterInput;
}

export interface ArticlesPayload {
  articles: {
    pageInfo: {
      startCursor: string;
      endCursor: string;
    };
    nodes?: Article[];
  };
}

const ARTICLES = gql`
  query Articles(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: ArticleFilterInput!
  ) {
    articles(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      nodes {
        ...ArticleFields
        author {
          id
          name
        }
      }
    }
  }
  ${ArticleFragment},
`;

export default ARTICLES;
