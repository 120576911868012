import { styled, alpha } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputBase from '@mui/material/InputBase';

export const SearchField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: alpha(theme.palette.common.white, 0.4),
    backgroundColor: "none",
    color: theme.palette.common.white
  },
  "& :hover.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#708090",
    backgroundColor: alpha("#708090", 0.2),

  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    backgroundColor: alpha("#708090", 0.2),

  },
  "& .MuiOutlinedInput-input": {
    color: theme.palette.common.white
  },
}));

export const MediumTextField = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: 20,
    fontWeight: 600,
    border: "none",
  },
}));

export const LargeTextField = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: 25,
    fontWeight: 600,
    border: "none",
  },
}));

const ZenTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.background.paper,
    "& fieldset": {
      borderColor: theme.palette.border.main,
    },
  },
}));

export default ZenTextField;
