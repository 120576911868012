import { gql } from "@apollo/client";
import ConversationType from "types/ConversationType";
import ConversationTypeFragment from "../fragments/ConversationTypeFragment";

export interface ConversationTypeInput {
    id: string;
}

export interface ConversationTypePayload {
    conversationType: ConversationType;
}

const CONVERSATION_TYPE = gql`
  query ConversationType($id: ID!) {
    conversationType(id: $id) {
      ...ConversationTypeFields
    }
  }
  ${ConversationTypeFragment}
`;

export default CONVERSATION_TYPE;
