import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "graphql/fragments/HelpdeskMessengerFragment";
import HelpdeskMessengerOptionFragment from "graphql/fragments/HelpdeskMessengerOptionFragment";
import ShopifyIntegrationFragment from "graphql/fragments/ShopifyIntegrationFragment";

export interface HelpdeskMessengerInput {
  id: string;
  defaultRoute?: string;
  previewAs?: string;
}

export interface HelpdeskMessengerPayload {
  helpdeskMessenger?: HelpdeskMessenger;
}

const HELPDESK_MESSENGER = gql`
  query HelpdeskMessenger($id: ID!, $defaultRoute: String, $previewAs: String) {
    helpdeskMessenger(id: $id) {
        ...HelpdeskMessengerFields
        options {
          nodes {
            ...HelpdeskMessengerOptionFields
          }
        }
        activeSupportTeam {
          nodes {
              id
              name
              avatarUrl
          }
        }
        shopifyIntegration {
          ...ShopifyIntegrationFields
        }
        previewHtml(defaultRoute: $defaultRoute, previewAs: $previewAs)
    }
  }
  ${HelpdeskMessengerFragment},
  ${HelpdeskMessengerOptionFragment},
  ${ShopifyIntegrationFragment}
`;

export default HELPDESK_MESSENGER;