import { styled } from "@mui/material/styles";
import Select, { SelectProps } from "@mui/material/Select";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";

export const ZenNativeSelect = styled(NativeSelect)<NativeSelectProps>(({ theme }) => ({
    "& .MuiNativeSelect-outlined": {
        backgroundColor: theme.palette.background.paper,
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& .MuiNativeSelect-outlined:focused": {
        backgroundColor: "red",
        "& fieldset": {
            borderColor: theme.palette.border.main,

        },
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.background.paper,
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& .MuiOutlinedInput-root:focused": {
        backgroundColor: "red",
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& fieldset": {
        borderColor: theme.palette.border.main,
    },
}));

const ZenSelect = styled(Select)<SelectProps>(({ theme }) => ({
    "& .MuiSelect-outlined": {
        backgroundColor: theme.palette.background.paper,
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& .MuiNativeSelect-outlined": {
        backgroundColor: theme.palette.background.paper,
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.background.paper,
        "& fieldset": {
            borderColor: theme.palette.border.main,
        },
    },
    "& fieldset": {
        borderColor: theme.palette.border.main,
    },
}));

export default ZenSelect;
