import './fonts/p22-mackinac-extra-bold.ttf';
import './fonts/p22-mackinac-pro-bold.ttf';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  // This is a production build

  const appsignal = new Appsignal({
    key: process.env.REACT_APP_APP_SIGNAL!,
  });

  // Start log rocket ()
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET!);
  setupLogRocketReact(LogRocket);

  ReactDOM.render(
    <ErrorBoundary instance={appsignal}>
      <App />
    </ErrorBoundary>,
    document.getElementById('root')
  );

} else {
  // This is a development build

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  reportWebVitals(console.log);
}


