import { gql } from "@apollo/client";
import ConversationType from "types/ConversationType";

export interface ConversationTypesSortInput {
  title?: "ASC" | "DESC",
}

export interface ConversationTypesFilterInput {
  searchQuery?: string;
  sort?: ConversationTypesSortInput;
}

export interface ConversationTypesInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: ConversationTypesFilterInput;
}

export interface ConversationTypesPayload {
  conversationTypes: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: ConversationType[];
  };
}

const CONVERSATION_TYPES = gql`
  query ConversationTypes(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: ConversationTypeFilterInput!
  ) {
    conversationTypes(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        title
        system
      }
    }
  }
`;

export default CONVERSATION_TYPES;