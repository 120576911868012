import { styled } from "@mui/material/styles";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";

const ZenDialogContent = styled(DialogContent)<DialogContentProps>(({ theme }) => ({
    "&.MuiDialogContent-root": {
        backgroundColor: theme.palette.background.default,
    },
    "&.MuiDialogContent-dividers": {
        backgroundColor: theme.palette.background.default,
    }
}));

export default ZenDialogContent;
