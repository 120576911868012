import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import { LeftAlignedButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Card from "theme/Card";
import SettingsIcon from '@mui/icons-material/Settings';
import useQueryParams from "hooks/useQueryParams";
import qs from 'query-string';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import MessengerSelection from "./selections/MessengerSelection";
import CreateDraftArticle from "./create_draft/CreateDraftArticle";
import EditArticle from "./edit/EditArticle";
import ArticlesGrid from "./ArticlesGrid";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HELPDESK_MESSENGERS, {
    HelpdeskMessengersInput,
    HelpdeskMessengersPayload
} from "graphql/queries/HelpdeskMessengersQuery";
import { useQuery } from "@apollo/client";
import useApp from "hooks/useApp";

export enum Selection {
    articles = "articles",
    createDraftArticle = "create_draft_article",
    editArticle = "edit_article",
    helpCenter = "helpcenter",
    settings = "settings",
    collections = "collections",
    messenger = "messenger",
}

type EndIconProps = {
    value: boolean;
};

function EndIcon(props: EndIconProps) {
    const { value } = props;
    const theme = useTheme();
    return (
        <Card variant="outlined" sx={{ padding: "2px", paddingLeft: "6px", paddingRight: "6px" }}>
            <Typography variant="body2" color={!!value ? theme.palette.success.main : theme.palette.info.main} fontWeight={600} >{!!value ? "On" : "Off"}</Typography>
        </Card>
    );
}

interface SideBarProps {
    selection: Selection;
    helpdeskMessenger?: HelpdeskMessenger;
}

function SideBar(props: SideBarProps) {
    const { selection } = props;
    const history = useHistory();

    function navigateToSelection(selection: Selection) {
        const query = { selection: selection.toString() };
        history.push({ pathname: "/articles", search: qs.stringify(query) });
    };

    return (
        <Stack spacing={1} id="HelpdeskSideMenu">
            <LeftAlignedButton
                startIcon={<MenuBookIcon />}
                color={
                    (selection === Selection.articles) ? "primary" : "secondary"
                }
                variant={
                    (selection === Selection.articles) ? "contained" : "text"
                }
                onClick={() => navigateToSelection(Selection.articles)}
            >
                <span style={{ width: "100%", textAlign: "left" }}>Articles</span>
            </LeftAlignedButton>
            <LeftAlignedButton
                color={selection === Selection.messenger ? "primary" : "secondary"}
                variant={selection === Selection.messenger ? "contained" : "text"}
                startIcon={<ForumOutlinedIcon />}
                onClick={() => navigateToSelection(Selection.messenger)}
            >
                <span style={{ width: "100%", textAlign: "left" }}>Messenger</span>
            </LeftAlignedButton>
            {false && <>
                <LeftAlignedButton
                    color={selection === Selection.helpCenter ? "primary" : "secondary"}
                    variant={selection === Selection.helpCenter ? "contained" : "text"}
                    startIcon={<HelpCenterIcon />}
                    endIcon={<EndIcon value={false} />}
                    onClick={() => navigateToSelection(Selection.helpCenter)}
                >
                    <span style={{ width: "100%", textAlign: "left" }}>Help Center</span>
                </LeftAlignedButton>
                <Stack sx={{ pl: 2 }}>
                    <LeftAlignedButton
                        color={
                            selection === Selection.collections ? "primary" : "secondary"
                        }
                        variant={
                            selection === Selection.collections ? "contained" : "text"
                        }
                        startIcon={<WorkspacesIcon />}
                        onClick={() => navigateToSelection(Selection.collections)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Collections</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        color={
                            selection === Selection.settings ? "primary" : "secondary"
                        }
                        variant={
                            selection === Selection.settings ? "contained" : "text"
                        }
                        startIcon={<SettingsIcon />}
                        onClick={() => navigateToSelection(Selection.settings)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Settings</span>
                    </LeftAlignedButton>
                </Stack> </>}
        </Stack>
    );
}

export default function HelpdeskMenu() {

    const theme = useTheme();
    const query = useQueryParams();
    const selection = query.get("selection") as Selection || Selection.messenger;

    const { data } = useQuery<HelpdeskMessengersPayload, HelpdeskMessengersInput>(HELPDESK_MESSENGERS, {
        variables: { filters: {} },
    });

    const renderEditArticle = () => {
        return (
            <EditArticle />
        )
    };

    const renderCreateDraftArticle = () => {
        return (
            <CreateDraftArticle />
        )
    };

    const renderMessenger = () => {
        return (
            <MessengerSelection />
        )
    };

    const renderArticlesGrid = () => {
        return (
            <ArticlesGrid />
        )
    };

    return (
        <Stack direction="row" spacing={2} p={2}>
            <Box sx={{ width: `calc(300px - ${theme.spacing(4)})` }}>
                <SideBar selection={selection} helpdeskMessenger={!!data ? (data.helpdeskMessengers.nodes.length > 0 ? data.helpdeskMessengers.nodes[0] : undefined) : undefined} />
            </Box>
            <Stack direction="column" spacing={2} sx={{ width: "calc(100% - 285px)" }}>
                {selection === Selection.editArticle && renderEditArticle()}
                {selection === Selection.createDraftArticle && renderCreateDraftArticle()}
                {selection === Selection.articles && renderArticlesGrid()}
                {selection === Selection.messenger && renderMessenger()}
            </Stack>
        </Stack>
    );
}
