import gql from "graphql-tag";

const ShopifyIntegrationFragment = gql`
  fragment ShopifyIntegrationFields on ShopifyIntegration {
    id
    shopDomain
  }
`;

export default ShopifyIntegrationFragment;
