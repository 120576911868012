import { gql } from "@apollo/client";
import ShopifyIntegration from "types/ShopifyIntegration";
import ShopifyIntegrationFragment from "../fragments/ShopifyIntegrationFragment";

export interface ShopifyIntegrationsInput { }

export interface ShopifyIntegrationsPayload {
  shopifyIntegrations: {
    pageInfo: {
      startCursor: string;
      endCursor: string;
    };
    nodes: ShopifyIntegration[];
  };
}

const SHOPIFY_INTEGRATIONS = gql`
  query ShopifyIntegrations {
    shopifyIntegrations {
      pageInfo {
        startCursor
        endCursor
      }
      nodes {
          ...ShopifyIntegrationFields
      }
    }
  }
  ${ShopifyIntegrationFragment}
`;

export default SHOPIFY_INTEGRATIONS;