import { useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import useTheme from "@mui/material/styles/useTheme";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import {
    useCommands,
    useActive,
    useChainedCommands,
    useCurrentSelection,
} from '@remirror/react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import ToggleButton from 'theme/ToggleButton';
import ToggleButtonGroup from 'theme/ToggleButtonGroup';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';

const Menu = (props: { rounded?: boolean, setLinkOpen: (open: boolean) => void }) => {
    const { setLinkOpen, rounded } = props;
    const isRounded = !!rounded;

    const { toggleBold, toggleItalic, toggleUnderline, toggleStrike, toggleHeading, toggleBlockquote, toggleOrderedList, toggleBulletList } = useCommands();
    const { empty } = useCurrentSelection();

    const active = useActive();
    const chain = useChainedCommands();
    const commands = useCommands();

    const theme = useTheme();

    const onRemove = useCallback(() => {
        return chain.removeLink().focus().run();
    }, [chain]);

    return (
        <Stack direction="row" spacing={1} sx={{
            borderRadius: isRounded ? "4px" : "0px",
            backgroundColor: "rgba(235, 235, 235, 1)",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            borderLeft: isRounded ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
            borderRight: isRounded ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
            padding: theme.spacing(0.5),
        }}>
            <ToggleButtonGroup size="small">
                <Tooltip arrow placement="top" title="Bold">
                    <ToggleButton size='small' value="bold" disabled={toggleBold.enabled() === false} selected={active.bold()} onClick={() => {
                        chain
                            .toggleBold()
                            .focus()
                            .run();
                    }}>
                        <FormatBoldIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Italic">
                    <ToggleButton value="italic" disabled={toggleItalic.enabled() === false} selected={active.italic()} onClick={() => {
                        chain
                            .toggleItalic()
                            .focus()
                            .run();
                    }}>
                        <FormatItalicIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Underline">
                    <ToggleButton value="underlined" disabled={toggleUnderline.enabled() === false} selected={active.underline()} onClick={() => {
                        chain
                            .toggleUnderline()
                            .focus()
                            .run();
                    }}>
                        <FormatUnderlinedIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Strike">
                    <ToggleButton value="strike" disabled={toggleStrike.enabled() === false} selected={active.strike()} onClick={() => {
                        chain
                            .toggleStrike()
                            .focus()
                            .run();
                    }}>
                        <StrikethroughSIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <div style={{ display: "inline-block", width: "1px", height: "30px", backgroundColor: "rgb(189, 189, 189)" }} />
            <Tooltip arrow placement="top" title="Heading">
                <ToggleButtonGroup size="small">
                    {[1, 2, 3].map((level) => (
                        <ToggleButton
                            value={level}
                            key={level}
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={() => commands.toggleHeading({ level })}
                            disabled={toggleHeading.enabled() === false}
                            selected={active.heading({ level })}
                        >
                            <Typography fontWeight={700}>H{level}</Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Tooltip>
            <div style={{ display: "inline-block", width: "1px", height: "30px", backgroundColor: "rgb(189, 189, 189)" }} />
            <ToggleButtonGroup size="small">
                <Tooltip arrow placement="top" title="Block Quote">
                    <ToggleButton value="blockquote" disabled={toggleBlockquote.enabled() === false} selected={active.blockquote()} onClick={() => {
                        chain
                            .toggleBlockquote()
                            .focus()
                            .run();
                    }}>
                        <FormatQuoteRoundedIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Bullet List">
                    <ToggleButton value="blockquote" disabled={toggleBulletList.enabled() === false} selected={active.bulletList()} onClick={() => {
                        chain
                            .toggleBulletList()
                            .focus()
                            .run();
                    }}>
                        <FormatListBulletedRoundedIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Numbered List">
                    <ToggleButton value="blockquote" disabled={toggleOrderedList.enabled() === false} selected={active.orderedList()} onClick={() => {
                        chain
                            .toggleOrderedList()
                            .focus()
                            .run();
                    }}>
                        <FormatListNumberedRoundedIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <div style={{ display: "inline-block", width: "1px", height: "30px", backgroundColor: "rgb(189, 189, 189)" }} />
            <ToggleButtonGroup size="small">
                <Tooltip arrow placement="top" title="Link">
                    <ToggleButton value="link" disabled={empty} selected={active.link()} onClick={() => {
                        setLinkOpen(true);
                    }}>
                        {!active.link() && <AddLinkRoundedIcon />}
                        {active.link() && <LinkRoundedIcon />}
                    </ToggleButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Remove Link">
                    <ToggleButton value="unlink" disabled={empty || !active.link()} selected={false} onClick={() => {
                        onRemove();
                    }}>
                        <LinkOffRoundedIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </Stack>
    );
};

export default Menu;