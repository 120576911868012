import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "graphql/fragments/HelpdeskMessengerFragment";
import HelpdeskMessengerOptionFragment from "graphql/fragments/HelpdeskMessengerOptionFragment";
import ShopifyIntegrationFragment from "graphql/fragments/ShopifyIntegrationFragment";

export interface UpdateHelpdeskMessengerInput {
  input: {
    id: string;
    name: string;
    live: boolean;
    messengerLocation: "bottom_left" | "bottom_right";
    faqEnabled: boolean;
    faqSuggestionsEnabled: boolean;
    somethingElseEnabled: boolean;
    somethingElseText: string;
    greetingText: string;
    greetingDescriptionHtml: string;
    needMoreHelpText: string;
    contactSupportTitle: string;
    contactSupportPlaceholder: string;
    contactSupportEmail: string;
    contactSupportSend: string;
    thankyouTitle: string;
    thankyouMessageHtml: string;
    haveAnotherQuestionText: string;
    specialNoticeText: string;
    specialNoticeShown: boolean;
    darkmodeSupported: boolean;
    backgroundColor: string;
    actionColor: string;
    promptForHelpdeskOptions: boolean;
    backgroundPatternSelection: number;
    backgroundPattern?: string;
  };
}

export interface UpdateHelpdeskMessengerPayload {
  updateHelpdeskMessenger: {
    errors: string[];
    helpdeskMessenger?: HelpdeskMessenger;
  };
}

const UPDATE_HELPDESK_MESSENGER = gql`
  mutation UpdateHelpdeskMessenger(
    $input: UpdateHelpdeskMessengerMutationInput!
  ) {
    updateHelpdeskMessenger(input: $input) {
      errors
      helpdeskMessenger {
        ...HelpdeskMessengerFields
        options {
          nodes {
            ...HelpdeskMessengerOptionFields
          }
        }
        shopifyIntegration {
          ...ShopifyIntegrationFields
        }
      }
    }
  }
  ${HelpdeskMessengerFragment},
  ${HelpdeskMessengerOptionFragment},
  ${ShopifyIntegrationFragment}
`;

export default UPDATE_HELPDESK_MESSENGER;