import { gql } from "@apollo/client";
import HelpdeskMessengerOption from "types/HelpdeskMessengerOption";
import HelpdeskMessengerOptionFragment from "graphql/fragments/HelpdeskMessengerOptionFragment";

export interface HelpdeskMessengerOptionInput {
    id: string;
}

export interface HelpdeskMessengerOptionPayload {
    helpdeskMessengerOption?: HelpdeskMessengerOption;
}

const HELPDESK_MESSENGER_OPTION = gql`
  query HelpdeskMessengerOption($id: ID!) {
    helpdeskMessengerOption(id: $id) {
        ...HelpdeskMessengerOptionFields
        conversationType {
            id
        }
        article {
            id
        }
        labels {
            nodes {
                id
            }
        }
    }
  }
  ${HelpdeskMessengerOptionFragment},
`;

export default HELPDESK_MESSENGER_OPTION;