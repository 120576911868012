import React from "react";
import {
  Route,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import ME, {
  MeInput,
  MePayload,
} from "graphql/queries/MeQuery";
import useApp from "hooks/useApp";
import AdminAppBar from "components/chrome/AdminAppBar";
import Errors from "./Errors";
import LogRocket from 'logrocket';
import Articles from "./Articles";
import useQueryParams from "hooks/useQueryParams";

type AdminComponentProps = {
  children?: React.ReactNode;
  hideAdminBar?: boolean;
};

function AdminComponent(props: AdminComponentProps) {
  const app = useApp();

  const { children } = props;

  const { data, error, loading } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  });

  if (!!error) {

    if (error.message === "This account has been banned." && window.location.pathname !== "/banned") {
      return <Errors errors={["This account has been banned."]} />;
    } else if (error.message === "This account has been locked." && window.location.pathname !== "/locked") {
      return <Errors errors={["This account has been locked."]} />;
    }

    if (error.message !== "2FA_REQUIRED" && error.message !== "PASSWORD_REQUIRED" && error.message !== "VERIFY_EMAIL" && error.message !== "VERIFY_PHONE_NUMBER" && error.message !== "PLAN_REQUIRED" && error.message !== "AVATAR_REQUIRED") {
      return <Errors errors={[error.message]} />;
    }
  }

  if (!!data && !!data.me) {

    LogRocket.identify(data.me.id, {
      name: data.me.name,
      email: data.me.email
    });

    if (data.me.banned) {
      return <Errors errors={["Your account has been banned. Please contact zenshop staff"]} />;
    } else if (data.me.locked) {
      return <Errors errors={["Your account has been locked. Please contact zenshop staff"]} />;
    }

    let selectedOrg = data.me?.selectedOrg;

    if (!!selectedOrg) {
      app.updateSelectedOrgID(selectedOrg.id);

      return <AdminAppBar me={data.me}>{children}</AdminAppBar>;
    } else {
      window.location.href = "https://admin.zenshop.app/select-org";
      return <div />;
    }
  }

  if (loading) {
    return <div />;
  }

  return <div />;
}

const AdminRoute = ({ component, ...rest }: any) => {

  const routeComponent = () => {
    const screen = React.createElement(component, {});
    return <AdminComponent {...rest}>{screen}</AdminComponent>;
  };

  return <Route {...rest} render={routeComponent} />;
};

function AuthorizedComponent(props: AdminComponentProps) {
  const app = useApp();
  const { children, hideAdminBar } = props;

  const { data, error, loading } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  });

  if (!!error) {

    if (error.message === "This account has been banned." && window.location.pathname !== "/banned") {
      return <Errors errors={["This account has been banned."]} />;
    } else if (error.message === "This account has been locked." && window.location.pathname !== "/locked") {
      return <Errors errors={["This account has been locked."]} />;
    }

    if (error.message !== "2FA_REQUIRED" && error.message !== "PASSWORD_REQUIRED" && error.message !== "VERIFY_EMAIL" && error.message !== "VERIFY_PHONE_NUMBER" && error.message !== "PLAN_REQUIRED" && error.message !== "AVATAR_REQUIRED") {
      return <Errors errors={[error.message]} />;
    }
  }

  if (!!data && !!data.me) {

    LogRocket.identify(data.me.id, {
      name: data.me.name,
      email: data.me.email
    });

    let selectedOrg = data.me?.selectedOrg;

    if (!!selectedOrg) {
      app.updateSelectedOrgID(selectedOrg.id);
    }

    if (data.me.banned) {
      return <Errors errors={["Your account has been banned. Please contact zenshop staff"]} />;
    } else if (data.me.locked) {
      return <Errors errors={["Your account has been locked. Please contact zenshop staff"]} />;
    }

    if (!!hideAdminBar) {
      return <>{children}</>
    } else {
      return <AdminAppBar me={data.me}>{children}</AdminAppBar>;
    }
  }

  if (loading) {
    return <div />;
  }

  return <div />;
}

function Router() {

  const params = useQueryParams();
  const authToken = params.get("auth-token") ?? undefined;
  const hideChrome = params.get("hide-chrome") ?? undefined;
  const app = useApp();
  const { updateToken } = useApp();

  if (!!hideChrome) {
    app.updateHideChrome(true);
  }

  if (!!authToken) {
    updateToken(authToken);
  }

  return (
    <BrowserRouter>
      <Switch>
        <AdminRoute path="/" exact component={Articles} />
        <AdminRoute path="/articles" component={Articles} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
