import { gql } from "@apollo/client";
import Label from "types/Label";
import LabelFragment from "../fragments/LabelFragment";

export interface LabelsSortInput {
  title?: "ASC" | "DESC",
}

export interface LabelsInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: LabelsFilterInput;
}
export interface LabelsFilterInput {
  searchQuery?: string;
  sort?: LabelsSortInput;
}

export interface LabelsPayload {
  labels: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Label[];
  };
}

const LABELS = gql`
  query Labels(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: LabelFilterInput!
  ) {
    labels(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
          ...LabelFields
      }
    }
  }
  ${LabelFragment}
`;

export default LABELS;