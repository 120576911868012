import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import HELPDESK_MESSENGERS, {
    HelpdeskMessengersInput,
    HelpdeskMessengersPayload
} from "graphql/queries/HelpdeskMessengersQuery";
import CREATE_DRAFT_HELPDESK_MESSENGER, {
    CreateDraftHelpdeskMessengerInput,
    CreateDraftHelpdeskMessengerPayload,
} from "graphql/mutations/CreateDraftHelpdeskMessengerMutation";
import { useQuery, useMutation } from "@apollo/client";
import EditHelpdeskMessengerPanel from "components/org_settings/helpdesk_messengers/EditHelpdeskMessengerPanel";
import Stack from "@mui/material/Stack";
import Button from "theme/Button";
import NewHelpdeskMessengerDialog from "components/org_settings/helpdesk_messengers/NewHelpdeskMessengerDialog";
import CircularProgress from "@mui/material/CircularProgress";

export default function MessengerSelection() {

    const { data, loading } = useQuery<HelpdeskMessengersPayload, HelpdeskMessengersInput>(HELPDESK_MESSENGERS, {
        variables: { filters: {} },
    });

    const [createDraft, { }] = useMutation<CreateDraftHelpdeskMessengerPayload, CreateDraftHelpdeskMessengerInput>(CREATE_DRAFT_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessengers"] });

    const [draftHelpdeskId, setDraftHelpdeskId] = useState<string | undefined>(undefined);
    const [newHelpdeskMessengerOpen, setNewHelpdeskMessengerOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!!draftHelpdeskId) {
            // Do nothing
        } else if (!!data && data.helpdeskMessengers.nodes.length > 0) {
            try {
                createDraft({ variables: { input: { helpdeskMessengerId: data.helpdeskMessengers.nodes[0].id } } })
                    .then((result) => {
                        const id = result.data?.createDraftHelpdeskMessenger?.draftHelpdeskMessenger?.id;

                        if (!!id) {
                            setDraftHelpdeskId(id);
                        }
                    });
            } catch { }
        }
    }, [data, draftHelpdeskId, setDraftHelpdeskId]);


    useEffect(() => {
        if (!!data && data.helpdeskMessengers.nodes.length == 0 && !!draftHelpdeskId) {
            setDraftHelpdeskId(undefined);
        }
    }, [data, draftHelpdeskId]);

    if (!!draftHelpdeskId) {
        return (
            <EditHelpdeskMessengerPanel id={draftHelpdeskId} />
        );
    } else if (!!loading) {
        return <><CircularProgress /></>
    } else if (!!data) {
        if (data.helpdeskMessengers.nodes.length === 0) {
            return (
                <Stack pl={4} pt={4} direction="column" spacing={2} sx={{ maxWidth: "800px" }}>
                    <Typography variant="h5">Create your first Helpdesk Messenger</Typography>
                    <Typography variant="subtitle2">You haven&apos;t created a messenger yet, get started now so you can answer customer questions on your store page.</Typography>
                    <Stack sx={{ width: "100%" }} direction="row" justifyContent="flex-start">
                        <NewHelpdeskMessengerDialog open={newHelpdeskMessengerOpen} onClose={() => {
                            setNewHelpdeskMessengerOpen(false);
                        }} />
                        <div>
                            <Button variant="contained" color="secondary" size="small" onClick={() => setNewHelpdeskMessengerOpen(true)}>Create messenger</Button>
                        </div>
                    </Stack>
                </Stack >
            );
        }
        else {
            return <><CircularProgress /></>
        }
    } else {
        return <><CircularProgress /></>
    }
}