import { StoreonStore } from 'storeon';
import State from "./State";

export default function ConversationStore(store: StoreonStore<State>) {
    store.on('@init', () => ({
        conversationIds: [],
        selectedConversationId: undefined,
        conversationsGridFilter: "INBOX",
        conversationsGridAssigneeIds: [],
        conversationsGridLabelIds: [],
        conversationsGridCoversationTypeId: undefined,
    }))

    store.on('conversations/add', ({ conversationIds }, conversationId: string) => {
        if (conversationIds.indexOf(conversationId) < 0) {
            conversationIds.unshift(conversationId);
            conversationIds.length = 4;
            return { conversationIds: conversationIds }
        } else {
            return { conversationIds: conversationIds }
        }
    });

    store.on('conversations/removeAll', ({ conversationIds }, conversationId: string) => {
        return { conversationIds: [] }
    });

    store.on('conversations/remove', ({ conversationIds }, conversationId: string) => {
        return { conversationIds: conversationIds.splice(conversationIds.indexOf(conversationId, 0), 1) }
    });

    store.on('conversations/setSelectedConversationId', ({ }, selectedConversationId: string | undefined) => {
        return { selectedConversationId: selectedConversationId }
    });

    store.on('conversations/setFilter', ({ }, conversationsGridFilter: string | undefined) => {
        return { conversationsGridFilter: conversationsGridFilter }
    });

    store.on('conversations/setAssignees', ({ }, conversationsGridAssigneeIds: string[] | undefined) => {
        return { conversationsGridAssigneeIds: conversationsGridAssigneeIds }
    });

    store.on('conversations/setLabels', ({ }, conversationsGridLabelIds: string[] | undefined) => {
        return { conversationsGridLabelIds: conversationsGridLabelIds }
    });

    store.on('conversations/setType', ({ }, conversationsGridCoversationTypeId: string | undefined) => {
        return { conversationsGridCoversationTypeId: conversationsGridCoversationTypeId }
    });
}