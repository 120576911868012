import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";

const ZenToggleButtonGroup = styled(ToggleButtonGroup)<ToggleButtonGroupProps>(({ theme }) => ({
    "&.MuiToggleButtonGroup-root": {
        color: theme.palette.text.primary,
        backgroundColor: "rgba(235, 235, 235, 1)",
        border: "none",
    },
}));

export default ZenToggleButtonGroup;
