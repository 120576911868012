import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "../fragments/HelpdeskMessengerFragment";

export interface CreateHelpdeskMessengerOptionInput {
  input: {
    helpdeskMessengerId: string;
    questionText: string;
    conversationTypeId?: string;
    labelIds: string[];
    articleId?: string;
  };
}

export interface CreateHelpdeskMessengerOptionPayload {
  createHelpdeskMessengerOption: {
    errors: string[];
    helpdeskMessenger?: HelpdeskMessenger;
  };
}

const CREATE_HELPDESK_MESSENGER_OPTION = gql`
  mutation CreateHelpdeskMessengerOption($input: CreateHelpdeskMessengerOptionMutationInput!) {
    createHelpdeskMessengerOption(input: $input) {
      errors
      helpdeskMessenger {
        ...HelpdeskMessengerFields
      }
    }
  }
  ${HelpdeskMessengerFragment}
`;

export default CREATE_HELPDESK_MESSENGER_OPTION;