import { styled } from "@mui/material/styles";
import AppBar, { AppBarProps } from "@mui/material/AppBar";

const ZenAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    "&.MuiAppBar-root": {
        border: "none",
    },
}));

export default ZenAppBar;
