import gql from "graphql-tag";

const LabelFragment = gql`
  fragment LabelFields on Label {
    id
    createdAt
    title
    colorHex
  }
`;

export default LabelFragment;
