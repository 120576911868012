import dateFnsParseISO from "date-fns/parseISO";
import dateFnsIsAfter from "date-fns/isAfter";
import dateFnsCompareAsc from "date-fns/compareAsc";
import dateFnsCompareDesc from "date-fns/compareDesc";
import dateFnsFormat from "date-fns/format";
import dateFnsSetHours from "date-fns/setHours";
import dateFnsSetMinutes from "date-fns/setMinutes";
import dateFnsSetSeconds from "date-fns/setSeconds";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInHours from "date-fns/differenceInHours";
import { utcToZonedTime } from "date-fns-tz";
import subDays from "date-fns/subDays";


// formatToTimeRemaining - formats a server side string to number of hours remaining
export function formatToTimeRemaining(dateString: string): string {
  const date = parseDateString(dateString);
  const timeAgo = subDays(new Date(), 1);
  const diff = differenceInHours(date, timeAgo);
  if (diff < 1) {
    return "Overdue";
  }

  return `${diff} hours left`;
}

// parseDateString - parses a server side string to a javascript date
export function parseDateString(dateString: string): Date {
  return dateFnsParseISO(dateString);
}

// formatDate - formats a date to a server side string
export function formatToUTCDate(date: Date): string {
  const utc = utcToZonedTime(date, "UTC");
  const formatted = `${dateFnsFormat(utc, `yyyy-MM-dd HH:mm:ss`)} +0000`;
  return formatted;
}

// formatDate - formats a date to a server side string
export function setToMidnight(date: Date): Date {
  const midnight = dateFnsSetMinutes(
    dateFnsSetHours(dateFnsSetSeconds(date, 0), 23),
    50
  );
  return midnight;
}

// formatDateStringToTimeAgo - formats a server side string to a 01st Jan 2019
export function formatDateStringToTimeAgo(dateString: string, always: boolean = false): string {
  const date = parseDateString(dateString);
  if (always) {
    const formatted = formatDistanceToNow(date, {
      includeSeconds: false,
      addSuffix: true,
      locale: undefined,
    });
    return formatted;
  }
  if (differenceInMilliseconds(new Date(), date) > 60000) {
    if (differenceInHours(new Date(), date) >= 24) {
      return formatDateStringToDDMMYYYYHHMM(dateString);
    }
    const formatted = formatDistanceToNow(date, {
      includeSeconds: false,
      addSuffix: true,
      locale: undefined,
    });
    return formatted;
  }
  return "Just now";
}

// formatDateToDDMMYYYY - formats a server side string to a 01st Jan 2019
export function formatDateToDDMM(date: Date): string {
  const formatted = dateFnsFormat(date, `do LLL`);
  return formatted;
}

// formatDateToDDMMYYYY - formats a server side string to a 01st Jan 2019
export function formatDateToDDMMYYYY(date: Date): string {
  const formatted = dateFnsFormat(date, `do LLL y`);
  return formatted;
}

// formatDateStringToDDMMYYYY - formats a server side string to a 01st Jan 2019
export function formatDateStringToDDMMYYYY(dateString: string): string {
  const date = parseDateString(dateString);
  const formatted = formatDateToDDMMYYYY(date);
  return formatted;
}

// formatDateStringToDDMMYYYY - formats a server side string to a 01st Jan 2019
export function formatDateStringToDDMMYYYYHHMM(dateString: string): string {
  const date = parseDateString(dateString);
  const formatted = dateFnsFormat(date, `do LLL y, HH:mm z`);
  return formatted;
}

// isAfter - checks two server date strings and compares isAfter
export function isAfter(dateString1: string, dateString2: string): Boolean {
  const date1 = parseDateString(dateString1);
  const date2 = parseDateString(dateString2);
  return dateFnsIsAfter(date1, date2);
}

// compareAsc - compares asc
export function compareAsc(dateString1: string, dateString2: string): number {
  const date1 = parseDateString(dateString1);
  const date2 = parseDateString(dateString2);
  return dateFnsCompareAsc(date1, date2);
}

// compareDesc - compares desc
export function compareDesc(dateString1: string, dateString2: string): number {
  const date1 = parseDateString(dateString1);
  const date2 = parseDateString(dateString2);
  return dateFnsCompareDesc(date1, date2);
}
