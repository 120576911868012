import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { styled, alpha } from "@mui/material/styles";

const ZenToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
    "&.MuiToggleButton-root": {
        padding: "2px",
        paddingLeft: "6px",
        paddingRight: "6px",
        border: "none",
        color: theme.palette.text.primary,
        backgroundColor: "rgba(235, 235, 235, 1)",
        marginRight: "4px",
        borderRadius: "4px !important",
    },
    "&.MuiToggleButton-root:hover": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px !important",
    },
    "&.Mui-disabled": {
        backgroundColor: alpha(theme.palette.text.secondary, 0.02),
        color: alpha(theme.palette.text.secondary, 0.2),
        borderRadius: "4px !important",
    },
    "&.Mui-selected": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: "4px !important",
    }
}));

export default ZenToggleButton;
