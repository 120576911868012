import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useQuery, useMutation } from "@apollo/client";
import CONVERSATION_TYPES, { ConversationTypesInput, ConversationTypesPayload } from "graphql/queries/ConversationTypesQuery";
import CREATE_CONVERSATION_TYPE, { CreateConversationTypeInput, CreateConversationTypePayload } from "graphql/mutations/CreateConversationTypeMutation";
import ConversationType from "types/ConversationType";
import { GridColDef, GridSelectionModel, GridCellParams } from '@mui/x-data-grid-pro';
import DataGrid from "theme/DataGrid";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import AddIcon from '@mui/icons-material/Add';
import { styled } from "@mui/material/styles";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import useTheme from "@mui/material/styles/useTheme";

const HiddenColumnGrid = styled(DataGrid)<DataGridProProps>(({ theme }) => ({
    "& .MuiDataGrid-columnHeaders": {
        display: "none",
    },
    "&& > *": {
        borderRadius: "4px",
    }
}));

export function renderConversationType(params: GridCellParams) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">{params.value["title"]}</Typography>
        </Stack>);
}

interface Props {
    open: boolean;
    selectedConversationTypeId?: string;
    onClose: (selectedConversationId?: string) => void;
}

const columns: GridColDef[] = [
    {
        field: 'conversationType',
        headerName: 'Select a conversation type for the topic',
        renderCell: renderConversationType,
        flex: 1,
        sortable: false
    }
];

export default function ChooseConversationTypeDialog(props: Props) {

    const { onClose, open, selectedConversationTypeId } = props;

    const [searchInput, setSearchInput] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(!!selectedConversationTypeId ? [selectedConversationTypeId] : []);
    const [createNew, setCreateNew] = useState<boolean>(false);
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const { data, error, loading } = useQuery<ConversationTypesPayload, ConversationTypesInput>(CONVERSATION_TYPES, { variables: { filters: { sort: { title: "ASC" } } } });
    const [createConversationType, { loading: createConversationTypeLoading }] = useMutation<CreateConversationTypePayload, CreateConversationTypeInput>(CREATE_CONVERSATION_TYPE, { refetchQueries: ["ConversationTypes"] });

    useEffect(() => {
        if (!!error) {
            setAlertError("Unable to fetch");
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    useEffect(() => {
        setSelectionModel(!!selectedConversationTypeId ? [selectedConversationTypeId] : []);
    }, [selectedConversationTypeId]);

    const handleClose = (selection?: string) => {
        if (!!selection) {
            onClose(selection);
        } else if (selectionModel.length > 0) {
            onClose(selectionModel[0] as string);
        } else {
            onClose();
        }
        setTimeout(() => {
            reset();
            setCreateNew(false);
            setAlertError(undefined);
            setSearchInput("");
        }, 200);
    };

    const onSubmitCreateConversationType = async (formData: { [x: string]: any; }) => {
        try {
            const { data } = await createConversationType({
                variables: {
                    input: {
                        title: formData.conversation_type_title,
                        descriptionHtml: "<p></p>"
                    }
                }
            });

            if (!!data) {
                if (data.createConversationType.errors.length > 0) {
                    setAlertError(data.createConversationType.errors[0]);
                } if (!!data.createConversationType.conversationType) {
                    setSelectionModel([data.createConversationType.conversationType.id]);
                    handleClose(data.createConversationType.conversationType.id);
                }
            }
        } catch {
            setAlertError('There was a problem creating this conversation type');
        }
    };

    const conversationTypes: ConversationType[] = data?.conversationTypes?.nodes ?? [];

    let rows = conversationTypes.map((conversationType) => {
        return {
            id: conversationType.id,
            conversationType: {
                title: conversationType.title,
            }
        }
    });

    let rowsMinusUnassiged = rows.filter((conversationType) => conversationType.conversationType.title.toLowerCase() !== "unassigned");

    if (searchInput.length > 0) {
        rows = rows.filter((obj) => obj.conversationType.title.toLowerCase().includes(searchInput.toLowerCase()))
    }

    const showingCreateNew = rowsMinusUnassiged.length === 0 && searchInput.length === 0;

    const renderTable = () => {
        if (createNew) {
            return (
                <Stack direction="column" spacing={2}>
                    <form onSubmit={handleSubmit(onSubmitCreateConversationType)}>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="outlined" fullWidth>
                                <Controller
                                    name="conversation_type_title"
                                    rules={{
                                        required: true,
                                        maxLength: 150,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id="conversation_type_title"
                                            type="conversation_type_title"
                                            sx={{ backgroundColor: theme.palette.background.paper }}
                                            fullWidth
                                            size="small"
                                            helperText={errors.conversation_type_title ? errors.conversation_type_title.message : undefined}
                                            variant="outlined"
                                            placeholder="Type"
                                            error={!!errors.conversation_type_title}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                />
                            </FormControl>
                        </Stack>
                    </form>
                </Stack>
            );
        }
        else if (showingCreateNew) {
            return (
                <form onSubmit={handleSubmit(onSubmitCreateConversationType)}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="body2" color="text.secondary">You haven&apos;t created any conversation types yet, create your first conversation type to get started.</Typography>
                        <div>
                            <Button startIcon={<OpenInNewIcon />} color="info" size="small">Read more on organizing your inbox</Button>
                        </div>
                        <FormControl variant="outlined">
                            <Controller
                                name="conversation_type_title"
                                rules={{
                                    required: true,
                                    maxLength: 150,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="conversation_type_title"
                                        type="conversation_type_title"
                                        sx={{ backgroundColor: theme.palette.background.paper }}
                                        size="small"
                                        helperText={errors.conversation_type_title ? errors.conversation_type_title.message : undefined}
                                        variant="outlined"
                                        placeholder="Type"
                                        error={!!errors.conversation_type_title}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                            />
                        </FormControl>
                    </Stack>
                </form>
            );
        } else {
            return (
                <Stack direction="column" spacing={2}>
                    <TextField
                        fullWidth
                        sx={{ backgroundColor: theme.palette.background.paper }}
                        size="small"
                        placeholder="Filter conversation types"
                        onChange={(e) => setSearchInput(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterListOutlinedIcon />
                                </InputAdornment>
                            ),
                        }} />
                    {!loading && <div style={{ height: 300, width: '100%' }}>
                        <HiddenColumnGrid
                            density="compact"
                            rows={rows}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                                handleClose(newSelectionModel[0] as string);
                            }}
                            columns={columns}
                            disableColumnMenu
                            disableColumnReorder
                            disableColumnResize
                            disableChildrenSorting
                            disableColumnSelector
                            hideFooter
                            headerHeight={0}
                            components={{
                                Panel: () => (<div />),
                                Header: () => (<div />),
                                Toolbar: () => (<div />)
                            }}
                        />
                    </div>}
                </Stack>
            );
        }
    }

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth={(createNew || showingCreateNew) ? "xs" : "sm"} fullWidth>
            <DialogTitle>
                {!(createNew || showingCreateNew) && <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Select a conversation type</Typography>
                    <div>
                        <Button startIcon={<AddIcon />} size="small" variant="contained" color="secondary" onClick={() => setCreateNew(true)}>New</Button>
                    </div>
                </Stack>}
                {!!(createNew || showingCreateNew) && <>
                    <Typography variant="h5">New conversation type</Typography>
                </>}
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    {renderTable()}
                </Stack>
            </DialogContent>
            {(createNew || showingCreateNew) && <DialogActions sx={{ p: 2 }}>
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <Button onClick={handleSubmit(onSubmitCreateConversationType)} size="small" disabled={createConversationTypeLoading} variant="contained" color="success">Create</Button>
            </DialogActions>}
            {!(createNew || showingCreateNew) && <DialogActions sx={{ p: 2 }}>
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
            </DialogActions>}
        </Dialog>
    );
}