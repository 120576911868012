import { styled } from "@mui/material/styles";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

const ZenDataGrid = styled(DataGridPro)<DataGridProProps>(({ theme }) => ({
  borderColor: theme.palette.border.main,
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: theme.palette.background.paper
  },
  "& .MuiDataGrid-columnSeparator": {
    color: theme.palette.border.main,
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottomColor: theme.palette.border.main,
  },
  "& .MuiDataGrid-overlay": {
  },
  "& .MuiDataGrid-root, .MuiDataGrid-row": {
  },
  "& .MuiDataGrid-root, .MuiDataGrid-footerContainer": {
    borderTopColor: theme.palette.border.main,
    borderRadius: 0,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  "& .MuiDataGrid-root, .MuiDataGrid-columnsContainer": {
    borderRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  "& .MuiTablePagination-root": {
    color: theme.palette.text.secondary,
  },
  "& .MuiDataGrid-root, .MuiDataGrid-cell": {
    color: "rgb(36, 41, 47)",
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  "& .MuiDataGrid-root, .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.text.secondary,
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
}));

export const NoScrollZenDataGrid = styled(ZenDataGrid)<DataGridProProps>(({ theme }) => ({
  "& .MuiDataGrid-virtualScroller": {
    overflowY: "hidden",
    backgroundColor: theme.palette.background.paper
  },
}));

export default ZenDataGrid;
