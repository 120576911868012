import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "../fragments/HelpdeskMessengerFragment";

export interface UpdateHelpdeskMessengerOptionInput {
    input: {
        id: string;
        questionText: string;
        conversationTypeId?: string;
        labelIds: string[];
        articleId?: string;
    };
}

export interface UpdateHelpdeskMessengerOptionPayload {
    updateHelpdeskMessengerOption: {
        errors: string[];
        helpdeskMessenger?: HelpdeskMessenger;
    };
}

const UPDATE_HELPDESK_MESSENGER_OPTION = gql`
  mutation UpdateHelpdeskMessengerOption($input: UpdateHelpdeskMessengerOptionMutationInput!) {
    updateHelpdeskMessengerOption(input: $input) {
      errors
      helpdeskMessenger {
        ...HelpdeskMessengerFields
      }
    }
  }
  ${HelpdeskMessengerFragment}
`;

export default UPDATE_HELPDESK_MESSENGER_OPTION;