import gql from "graphql-tag";

const HelpdeskMessengerOptionFragment = gql`
  fragment HelpdeskMessengerOptionFields on HelpdeskMessengerOption {
    id
    questionText
    live
    deletable
  }
`;

export default HelpdeskMessengerOptionFragment;