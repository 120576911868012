import React, { useState, useEffect } from "react";

export const AppContext = React.createContext<{
  hideChrome: boolean;
  selectedOrgID?: string;
  token?: string;
  cubeToken?: string;
  prefersDarkMode?: boolean;
  dashboardBetaNag: boolean;
  updateHideChrome: (hideChrome: boolean) => void;
  updateSelectedOrgID: (selectedOrgID: string | undefined) => void;
  updateToken: (token: string | undefined) => void;
  updateCubeToken: (token: string | undefined) => void;
  updatePrefersDarkMode: (prefersDarkMode: boolean | undefined) => void;
  updateDashboardBetaNag: (dashboardBetaNag: boolean) => void;
}>({
  hideChrome: false,
  selectedOrgID: undefined,
  token: undefined,
  cubeToken: undefined,
  prefersDarkMode: undefined,
  dashboardBetaNag: false,
  updateHideChrome: (hideChrome: boolean) => { },
  updateSelectedOrgID: (selectedOrgID: string | undefined) => { },
  updateToken: (token: string | undefined) => { },
  updateCubeToken: (token: string | undefined) => { },
  updatePrefersDarkMode: (prefersDarkMode: boolean | undefined) => { },
  updateDashboardBetaNag: (dashboardBetaNag: boolean) => { },
});

export const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  const [prefersDarkMode, setPrefersDarkMode] = useState<boolean | undefined>(
    localStorage.getItem("prefersDarkMode") !== null
      ? Boolean(localStorage.getItem("prefersDarkMode"))
      : undefined
  );

  const updatePrefersDarkMode = (prefersDarkMode: boolean | undefined) => {
    setPrefersDarkMode(prefersDarkMode);
  };

  const [hideChrome, setHideChrome] = useState<boolean>(false);

  const updateHideChrome = (hideChrome: boolean) => {
    setHideChrome(hideChrome);
  };

  const [token, setToken] = useState<string | undefined>(
    localStorage.getItem("token") || undefined
  );

  const [cubeToken, setCubeToken] = useState<string | undefined>(
    localStorage.getItem("cube_token") || undefined
  );

  const [dashboardBetaNag, setDashboardBetaNag] = useState<boolean>(
    (localStorage.getItem("dashboard_beta_nag") || "true") === "true"
  );


  const updateToken = (token: string | undefined) => {
    if (!!token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
    setToken(token);
  };

  const updateCubeToken = (token: string | undefined) => {
    if (!!token) {
      localStorage.setItem("cube_token", token);
    } else {
      localStorage.removeItem("cube_token");
    }
    setCubeToken(token);
  };

  const [selectedOrgID, setSelectedOrgID] = useState<string | undefined>(
    localStorage.getItem("selected_org_id") || undefined
  );

  const updateSelectedOrgID = (selectedOrgID: string | undefined) => {
    setSelectedOrgID(selectedOrgID);
  };

  const updateDashboardBetaNag = (dashboardBetaNag: boolean) => {
    setDashboardBetaNag(dashboardBetaNag);
  };

  useEffect(() => {
    if (!!selectedOrgID) {
      localStorage.setItem("selected_org_id", selectedOrgID);
    } else {
      localStorage.removeItem("selected_org_id");
    }
  }, [selectedOrgID]);

  useEffect(() => {
    if (!!token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    if (!!cubeToken) {
      localStorage.setItem("cube_token", cubeToken);
    } else {
      localStorage.removeItem("cube_token");
    }
  }, [cubeToken]);

  useEffect(() => {
    if (prefersDarkMode !== undefined) {
      localStorage.setItem(
        "prefersDarkMode",
        prefersDarkMode ? "true" : "false"
      );
    } else {
      localStorage.removeItem("prefersDarkMode");
    }
  }, [prefersDarkMode])

  useEffect(() => {
    localStorage.setItem("dashboard_beta_nag", String(dashboardBetaNag))
  }, [dashboardBetaNag]);

  return (
    <AppContext.Provider
      value={{
        hideChrome,
        selectedOrgID,
        token,
        cubeToken,
        prefersDarkMode,
        dashboardBetaNag,
        updateHideChrome,
        updateSelectedOrgID,
        updateToken,
        updateCubeToken,
        updatePrefersDarkMode,
        updateDashboardBetaNag,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => React.useContext(AppContext);
