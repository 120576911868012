import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Button from "theme/Button";
import { useHistory } from "react-router-dom";
import Card from "theme/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion/dist/framer-motion";

interface Props {
    errors?: string[];
}

function Errors(props: Props) {
    const { errors } = props;

    const history = useHistory();

    let message = !!errors ? errors[0] : "Unicorns are looking into the problem ❤️";

    if (message === "Load failed") {
        message = "zenshop might not be responding";
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "rgb(0, 0, 0)",
                backgroundImage: "linear-gradient(to right, rgb(0, 0, 0), rgb(17, 24, 28))"
            }}
        >
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }}>
                <Card elevation={5} sx={{ maxWidth: 500 }}>
                    <Stack direction="column" spacing={2} p={3}>
                        <motion.div initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .3, type: 'spring', bounce: 0.25 }}>
                            <Typography textAlign="center" variant="h5">Oh no...</Typography>
                        </motion.div>
                        <motion.div initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .4, type: 'spring', bounce: 0.25 }}>
                            <Typography textAlign="center" variant="h5">We encountered a problem</Typography>
                        </motion.div>
                        <motion.div initial={{ transform: "translate(-80%, -40%)", opacity: 0 }} viewport={{ once: true }} whileInView={{ transform: "translate(0%, 0%)", opacity: 1 }} transition={{ delay: .8, duration: 0.6, type: 'spring', bounce: 0.25 }}>
                            <img src="/images/unicorn.png" height={300} alt="unicorn" />
                        </motion.div>
                        <Stack alignItems="center">
                            <motion.div initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5, type: 'spring', bounce: 0.25 }}>
                                <Alert sx={{ textAlign: "center" }} icon={false} severity="info">{<Typography sx={{ width: "100%" }} variant="body2" textAlign="center">{message}</Typography>}</Alert>
                            </motion.div>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} sx={{ pt: 2 }}>
                            <motion.div initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .7, type: 'spring', bounce: 0.25 }}>
                                <div>
                                    <Button size="small" onClick={() => { window.open(process.env.REACT_APP_DISCORD_URL, "_blank"); }}>Contact support</Button>
                                </div>
                            </motion.div>
                            <motion.div initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .6, type: 'spring', bounce: 0.25 }}>
                                <div>
                                    <Button size="small" color="secondary" variant='contained' onClick={() => history.push("/login")}>Return to login</Button>
                                </div>
                            </motion.div>
                        </Stack>
                    </Stack>
                </Card>
            </motion.div>
        </Box>

    );
}

export default Errors;
