import Box from "@mui/material/Box";
import HelpdeskMenu from "components/articles/HelpdeskMenu";

function AddressBook() {
    return (
        <Box>
            <HelpdeskMenu />
        </Box>
    );
}

export default AddressBook;
