import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQuery } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import CREATE_HELPDESK_MESSENGER, { CreateHelpdeskMessengerInput, CreateHelpdeskMessengerPayload } from "graphql/mutations/CreateHelpdeskMessengerMutation";
import SHOPIFY_INTEGRATIONS, { ShopifyIntegrationsInput, ShopifyIntegrationsPayload } from "graphql/queries/ShopifyIntegrationsQuery";
import { useHistory } from "react-router-dom";
import TextField from 'theme/TextField';
import Alert from '@mui/material/Alert';
import InputLabel from "@mui/material/InputLabel";
import Select from "theme/Select";
import useApp from "hooks/useApp";
import ShopifyIntegration from "types/ShopifyIntegration";

export interface NewHelpdeskMessengerDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function NewHelpdeskMessengerDialogWrapper(props: NewHelpdeskMessengerDialogProps) {

    const { open, onClose } = props;
    const app = useApp();
    const orgId = app.selectedOrgID;
    const { enqueueSnackbar } = useSnackbar();

    const { data } = useQuery<ShopifyIntegrationsPayload, ShopifyIntegrationsInput>(SHOPIFY_INTEGRATIONS, {
        variables: { orgId: orgId! },
    });


    if (open && !!data && !!data.shopifyIntegrations) {
        if (!!data.shopifyIntegrations && data.shopifyIntegrations.nodes.length > 0) {
            return <NewHelpdeskMessengerDialog shopifyIntegrations={data.shopifyIntegrations.nodes}  {...props} />
        } else {
            enqueueSnackbar('You must first create a Shopify integration', { variant: 'error' });
            onClose();
            return null;
        }
    }

    return null;
}

interface NewHelpdeskMessengerDialogInnerProps extends NewHelpdeskMessengerDialogProps {
    shopifyIntegrations: ShopifyIntegration[];
}

function NewHelpdeskMessengerDialog(props: NewHelpdeskMessengerDialogInnerProps) {

    const {
        control,
        trigger,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isValid },
    } = useForm();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [createHelpdeskMessenger, _] = useMutation<
        CreateHelpdeskMessengerPayload,
        CreateHelpdeskMessengerInput
    >(CREATE_HELPDESK_MESSENGER, { refetchQueries: ["HelpdeskMessengers"] });

    const { onClose, open, shopifyIntegrations } = props;

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (formData: { [x: string]: any; }) => {
        try {
            const { data } = await createHelpdeskMessenger({
                refetchQueries: ["HelpdeskMessengers", "HelpdeskMessenger"],
                variables: {
                    input: {
                        name: formData.name,
                        shopifyIntegrationId: formData.shopify_integration_id
                    }
                }
            });

            if (!!data?.createHelpdeskMessenger.helpdeskMessenger) {
                enqueueSnackbar(`🚀 Created messenger`);
                history.push(`/articles?selection=messenger`);
                onClose();
            } else if (!!data?.createHelpdeskMessenger && data.createHelpdeskMessenger.errors.length > 0) {
                setAlertError(data.createHelpdeskMessenger.errors[0]);
            }

        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    };

    let options = shopifyIntegrations.map((shopifyIntegration) => <option key={shopifyIntegration.id} value={shopifyIntegration.id}>{shopifyIntegration.shopDomain}</option>) ?? [];

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);

    useEffect(() => {
        if (shopifyIntegrations.length > 0) {
            setValue("shopify_integration_id", shopifyIntegrations[0].id);
        } else {

        }
    }, [shopifyIntegrations])

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 12 }}>New Messenger
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 12,
                            color: "text.secondary",
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack direction="column" spacing={2}>
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <FormControl variant="outlined" sx={{ pt: 1 }}>
                            <Controller
                                name="name"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="name"
                                        type="name"
                                        size="small"
                                        helperText={errors.name ? errors.name.message : undefined}
                                        variant="outlined"
                                        placeholder="Name"
                                        error={!!errors.name}
                                        onChange={(e) => { onChange(e); trigger("name"); }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a name for the messenger",
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="integration-label">Location</InputLabel>
                            <Controller
                                name="shopify_integration_id"
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        labelId="integration-label"
                                        id="shopify_integration_id"
                                        value={value}
                                        label="Location"
                                        onChange={onChange}
                                        native
                                        size="small"
                                    >
                                        {options}
                                    </Select>
                                )}
                                control={control}
                                defaultValue="US"
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <div>
                        <Button size="small" type="submit" disabled={!isValid} fullWidth color="success" variant="contained">Continue</Button>
                    </div>
                </DialogActions>
            </form>
        </Dialog >
    );
}