import { gql } from "@apollo/client";
import HelpdeskMessenger from "types/HelpdeskMessenger";
import HelpdeskMessengerFragment from "../fragments/HelpdeskMessengerFragment";

export interface CreateHelpdeskMessengerInput {
  input: {
    name: string;
    shopifyIntegrationId?: string;
  };
}

export interface CreateHelpdeskMessengerPayload {
  createHelpdeskMessenger: {
    errors: string[];
    helpdeskMessenger?: HelpdeskMessenger;
  };
}

const CREATE_HELPDESK_MESSENGER = gql`
  mutation CreateHelpdeskMessenger($input: CreateHelpdeskMessengerMutationInput!) {
    createHelpdeskMessenger(input: $input) {
      errors
      helpdeskMessenger {
        ...HelpdeskMessengerFields
      }
    }
  }
  ${HelpdeskMessengerFragment}
`;

export default CREATE_HELPDESK_MESSENGER;